import { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid'
import { AUTOMATION_LOG_LIMIT } from 'api/src/common/constants'
import { GetAutomationLog, GetAutomationLogVariables } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'

import { GET_AUTOMATION_LOG } from '../queries'

import AutomationHistoryLog from './AutomationHistoryLog'

export type AutomationLogQueryType =
  GetAutomationLog['automationLogsByAutomationId'][0]

interface AutomationHistoryProps {
  automationId: number
  handleViewDetails: (rowId: number) => void
}

const AutomationHistory: FC<AutomationHistoryProps> = ({
  automationId,
  handleViewDetails,
}) => {
  const [automationLogs, setAutomationLogs] = useState<
    AutomationLogQueryType[]
  >([])
  const [automationLogsLoading, setAutomationLogsLoading] =
    useState<boolean>(true)

  const [pagination, setPagination] = useState<number>(0)
  const [disablePagination, setDisablePagination] = useState<boolean>(false)

  const [getAutomationLogs] = useLazyQuery<
    GetAutomationLog,
    GetAutomationLogVariables
  >(GET_AUTOMATION_LOG, {
    onCompleted: (data) => {
      const logs = [automationLogs, data?.automationLogsByAutomationId].flat()
      setAutomationLogs(logs)
      setAutomationLogsLoading(false)

      if (data?.automationLogsByAutomationId?.length < AUTOMATION_LOG_LIMIT) {
        setDisablePagination(true)
      }
    },
    onError: () => {
      setAutomationLogsLoading(false)
    },
  })

  const refreshHistory = async () => {
    setAutomationLogsLoading(true)
    setAutomationLogs([])
    setPagination(0)
    setDisablePagination(false)
    await getAutomationLogs({
      variables: { automationId, pagination: 0 },
    })
  }

  const loadMoreResults = async () => {
    setAutomationLogsLoading(true)
    await getAutomationLogs({
      variables: {
        automationId,
        pagination: pagination + AUTOMATION_LOG_LIMIT,
      },
    })
    setPagination(pagination + AUTOMATION_LOG_LIMIT)
  }

  useEffect(() => {
    const fetchAutomationLogs = async (automationId) => {
      await getAutomationLogs({ variables: { automationId, pagination } })
    }

    // If there is an attached automation id - search for it
    if (automationId) {
      setAutomationLogsLoading(true)
      fetchAutomationLogs(automationId)
    }
  }, [automationId])

  const CustomFooter = () => (
    <GridFooterContainer>
      <div className="px-2">
        <Button
          variant="outlined"
          fullWidth={false}
          disabled={disablePagination || automationLogsLoading}
          onClick={loadMoreResults}
        >
          Load more
        </Button>
      </div>
      <GridFooter />
    </GridFooterContainer>
  )

  return (
    <>
      <div className={'flex h-[calc(100vh-140px)] w-full flex-col'}>
        <div className="flex items-center justify-between border-b bg-gray-50 px-5 py-3">
          <p className="flex flex-col gap-1 text-lg">Run History</p>
          <Button
            variant="outlined"
            fullWidth={false}
            loading={automationLogsLoading}
            startIcon={<ArrowPathIcon className="h-5 w-5" />}
            onClick={refreshHistory}
          >
            Refresh
          </Button>
        </div>
        <AutomationHistoryLog
          automationLogs={[automationLogs].flat()}
          handleViewDetails={handleViewDetails}
          CustomFooter={CustomFooter}
          loading={automationLogsLoading}
        />
      </div>
    </>
  )
}

export default AutomationHistory
