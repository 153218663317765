import { FC } from 'react'

import { EnrichedActionItemType } from 'src/components/Automations/utils'

import { getCleanedValue } from './AutomationTrackFilter'
import { ValueChip, ValueChipTheme } from './AutomationTrackValueChip'

interface AutomationTrackActionItemBaserowProps {
  action: EnrichedActionItemType
}

const AutomationTrackActionItemBaserow: FC<
  AutomationTrackActionItemBaserowProps
> = ({ action }) => {
  const actionParams = action?.inputType?.params
  const secondParamRequired =
    actionParams?.['2']?.dependency?.conditions?.[actionParams?.['1']?.value]

  return (
    <div className="rounded border border-gray-300 bg-white px-3 py-4">
      <p className="items-center gap-2 text-gray-600">
        <span className="mr-2 font-light">Field</span>
        <ValueChip value={action?.fieldName} theme={ValueChipTheme.BLUE} />
        <span className="grow pr-2 font-light lowercase">
          {action?.inputType?.value === 'clear'
            ? getCleanedValue(
                'CLEAR VALUE',
                action?.fieldType,
                ValueChipTheme.RED,
              )
            : action?.inputType?.label}
        </span>
        <span className="items-center gap-2 text-gray-600">
          <span className="inline-block py-2">
            {getCleanedValue(
              actionParams?.['1']?.valueLabel,
              action?.fieldType,
              ValueChipTheme.GREEN,
            )}
          </span>
          {secondParamRequired && (
            <span className="inline-block py-2">
              {getCleanedValue(
                actionParams?.['2']?.value,
                action?.fieldType,
                ValueChipTheme.GREEN,
              )}
            </span>
          )}
        </span>
      </p>
    </div>
  )
}

export default AutomationTrackActionItemBaserow
