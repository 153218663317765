import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { FormControl, FormHelperText, TextField } from '@mui/material'

import { FormInputRow } from '../Goals/Templates/TemplateEditForm'

import { FormErrors } from './AutomationModals/CreateAutomationModal'

interface CreateNewAutomationFormProps {
  setNewAutomationName: Dispatch<SetStateAction<string>>
  setNewAutomationDescription: Dispatch<SetStateAction<string>>
  formErrors: FormErrors
  setFormErrors: Dispatch<SetStateAction<FormErrors>>
  createAutomationIsLoading: boolean
}

const CreateNewAutomationForm: FC<CreateNewAutomationFormProps> = ({
  setNewAutomationName,
  setNewAutomationDescription,
  formErrors,
  setFormErrors,
  createAutomationIsLoading,
}) => {
  // Automation Name State
  const [automationNameInput, setAutomationNameInput] = useState<string>('')
  // Automation Description State
  const [automationDescriptionInput, setAutomationDescriptionInput] =
    useState<string>('')

  // Save name input for create
  useEffect(() => {
    setNewAutomationName(automationNameInput)

    // Revalidate field
    if (automationNameInput) {
      setFormErrors({ ...formErrors, name: null })
    }
  }, [automationNameInput])

  // Save description input for create
  useEffect(() => {
    setNewAutomationDescription(automationDescriptionInput)
  }, [automationDescriptionInput])

  return (
    <div className="pb-6">
      <FormInputRow label="Name">
        <FormControl fullWidth error={formErrors.name ? true : false}>
          <TextField
            disabled={createAutomationIsLoading}
            data-intercom-target="automation-name-input"
            data-testid="automation-name-input"
            size="small"
            fullWidth
            error={formErrors.name ? true : false}
            placeholder="Automation Name"
            value={automationNameInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAutomationNameInput(event.target.value)
            }}
            required
          />
          <FormHelperText>{formErrors.name}</FormHelperText>
        </FormControl>
      </FormInputRow>
      <FormInputRow label="Description">
        <TextField
          size="small"
          disabled={createAutomationIsLoading}
          data-intercom-target="automation-description-input"
          fullWidth
          placeholder="Automation Description"
          value={automationDescriptionInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setAutomationDescriptionInput(event.target.value)
          }}
        />
      </FormInputRow>
    </div>
  )
}

export default CreateNewAutomationForm
