import { FC } from 'react'

const AutomationTrackConditional: FC<{
  text: string
  compressed?: boolean
}> = ({ text, compressed = false }) => {
  return (
    <p
      className={`text-gray-500 capitalize m-0 py-1 text-sm text-center ${
        compressed ? '!my-1' : 'my-2'
      }`}
    >
      {text}
    </p>
  )
}

export default AutomationTrackConditional
