import React, { FC, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/outline'
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Autocomplete, Box, Stack, TextField, Tooltip } from '@mui/material'
import { useDrop } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid'

import {
  BaserowField,
  RequirementGroupType,
  RequirementType,
} from 'src/components/Automations/utils'
import Button from 'src/components/Library/Button'

import RequirementComponent from './RequirementComponent'

interface RequirementGroupComponentProps {
  group: RequirementGroupType
  onUpdate: (updatedGroup: RequirementGroupType | null) => void
  handleUpdateRequirementDnD: (id: string, groupId: string) => void
  availableFields: BaserowField[]
  topLevel?: boolean
  fieldsToMatch?: BaserowField[]
}

const logicOptions = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
]

type LogicOptionType = {
  value: string
  label: string
}

const RequirementGroupComponent: FC<RequirementGroupComponentProps> = ({
  group,
  onUpdate,
  handleUpdateRequirementDnD,
  availableFields,
  topLevel = false,
  fieldsToMatch,
}) => {
  const [selectedRule, setSelectedRule] = useState<LogicOptionType>(
    group?.rule
      ? logicOptions.find((option) => option.value === group.rule)
      : logicOptions[0],
  )

  const [ruleInputValue, setRuleInputValue] = useState<string>('')

  // DND disabled this can be removed / commented
  const [, dropRef] = useDrop(() => ({
    accept: 'REQUIREMENT',
    drop: (item: Record<string, any>, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        handleUpdateRequirementDnD(item.id, group.id)
      }
    },
  }))

  const handleRuleChange = (event) => {
    setSelectedRule(event)
    onUpdate({ ...group, rule: event.value })
  }

  const handleUpdateRequirement = (
    updatedReq: RequirementGroupType | RequirementType,
    index: number,
  ) => {
    const updatedRequirements = [...group.requirements]
    if (updatedReq === null) {
      updatedRequirements.splice(index, 1)
    } else {
      updatedRequirements[index] = updatedReq
    }
    onUpdate({ ...group, requirements: updatedRequirements })
  }

  const addNewRequirement = () => {
    const newRequirement: RequirementType = {
      id: uuidv4(),
      fieldName: '',
      fieldType: '',
      fieldId: null,
      filter: { value: '', label: '', params: {} },
    }
    onUpdate({
      ...group,
      requirements: [...group.requirements, newRequirement],
    })
  }

  const addNewSubgroup = () => {
    const newSubgroup: RequirementGroupType = {
      id: uuidv4(),
      rule: 'AND',
      requirements: [],
    }
    onUpdate({ ...group, requirements: [...group.requirements, newSubgroup] })
  }

  const deleteGroup = () => {
    onUpdate(null)
  }

  return (
    <>
      <div
        className={`
        border rounded border-gray-300 bg-white !m-0
        ${topLevel && 'border-white'}
        `}
      >
        <div className={'w-full p-0'} ref={dropRef}>
          <Box className="flex flex-col">
            {topLevel ? (
              <>
                <div className="flex items-center justify-end gap-4 px-0 py-2">
                  <Tooltip title={'Clear All'}>
                    <div>
                      <Button
                        fullWidth={false}
                        variant="text"
                        className="min-w-[0] rounded-full"
                        onClick={deleteGroup}
                      >
                        <TrashIcon className="w-6 h-6 text-gray-500" />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-between gap-4 border-b px-4 py-2">
                <p className="font-bold">Group</p>
                <Tooltip title={'Remove Group'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="text"
                      className="min-w-[0] rounded-full"
                      onClick={deleteGroup}
                    >
                      <XMarkIcon className="w-6 h-6 text-gray-500" />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
            <div className={`${!topLevel && 'p-3 py-4 bg-gray-50 rounded-b'}`}>
              <div className="flex items-center justify-end gap-2 w-full border-0 py-4 pt-0">
                <div className="grow flex gap-1 font-bold">
                  <Button
                    fullWidth={false}
                    variant="outlined"
                    className="min-w-[0] bg-white  hover:bg-indigo-100 px-4"
                    onClick={addNewRequirement}
                    startIcon={<PlusIcon className="w-4 h-4" />}
                  >
                    <span className="pt-0.5">Condition</span>
                  </Button>
                  <Button
                    fullWidth={false}
                    variant="outlined"
                    className="min-w-[0]  bg-white hover:bg-indigo-100 px-4"
                    onClick={addNewSubgroup}
                    startIcon={<PlusIcon className="w-4 h-4" />}
                  >
                    <span className="pt-0.5">Group</span>
                  </Button>
                </div>
                <Autocomplete
                  disableClearable
                  value={selectedRule}
                  onChange={(_event: any, newValue: LogicOptionType) => {
                    handleRuleChange(newValue)
                  }}
                  inputValue={ruleInputValue}
                  onInputChange={(_event, newInputValue) => {
                    setRuleInputValue(newInputValue)
                  }}
                  size="small"
                  className={'w-[120px]'}
                  options={logicOptions}
                  id="select-logical-type"
                  renderInput={(params) => (
                    <TextField {...params} className="bg-white" />
                  )}
                />
              </div>

              {group?.requirements?.length > 0 && (
                <Stack spacing={2} className="gap-4">
                  {/* This needs to be this fucky wucky way because the array index is VERY IMPORTANT to the other functions. We need to be specific abut the index otherwise it puts things in wrong places */}
                  {group.requirements.map((req, reqIndex) => {
                    if (
                      !(
                        Object.hasOwn(req, 'rule') &&
                        Object.hasOwn(req, 'requirements')
                      )
                    ) {
                      return (
                        <RequirementComponent
                          availableFields={availableFields}
                          key={reqIndex}
                          requirement={req as RequirementType}
                          onUpdate={(updatedReq) =>
                            handleUpdateRequirement(updatedReq, reqIndex)
                          }
                          fieldsToMatch={fieldsToMatch}
                        />
                      )
                    }
                    return null
                  })}
                  {group.requirements.map((req, index) => {
                    if (
                      Object.hasOwn(req, 'rule') &&
                      Object.hasOwn(req, 'requirements')
                    ) {
                      return (
                        <RequirementGroupComponent
                          availableFields={availableFields}
                          handleUpdateRequirementDnD={
                            handleUpdateRequirementDnD
                          }
                          key={index}
                          group={req as RequirementGroupType}
                          onUpdate={(updatedGroup) =>
                            handleUpdateRequirement(updatedGroup, index)
                          }
                          fieldsToMatch={fieldsToMatch}
                        />
                      )
                    }
                    return null
                  })}
                </Stack>
              )}
            </div>
          </Box>
        </div>
      </div>
    </>
  )
}

export default RequirementGroupComponent
