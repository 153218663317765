import { FC } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { Chip } from '@mui/material'
import { DataGrid, GridColDef, type GridSlots } from '@mui/x-data-grid'
import { AUTOMATION_LOG_LIMIT } from 'api/src/common/constants'
import dayjs from 'dayjs'

import IconButton from 'src/components/Library/IconButton'

import { AutomationLogQueryType } from './AutomationHistory'

interface AutomationHistoryLogProps {
  automationLogs: AutomationLogQueryType[]
  handleViewDetails: (rowId: number) => void
  CustomFooter: GridSlots['footer']
  loading: boolean
}

const AutomationHistoryLog: FC<AutomationHistoryLogProps> = ({
  automationLogs,
  handleViewDetails,
  CustomFooter,
  loading,
}) => {
  const historyColumns: GridColDef<HistoryRow>[] = [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 1,
      disableColumnMenu: false,
      filterable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      disableColumnMenu: false,
      filterable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => {
        if (param.row.status === 'COMPLETED') {
          return (
            <Chip
              size="small"
              className="w-full rounded bg-emerald-100 py-1 text-sm text-emerald-500"
              label="Success"
            />
          )
        } else if (param.row.status === 'FAILED') {
          return (
            <Chip
              size="small"
              className="w-full rounded bg-red-100 py-1 text-sm text-red-500"
              label="Fail"
            />
          )
        } else if (param.row.status === 'RUNNING') {
          return (
            <Chip
              size="small"
              className="w-full rounded bg-blue-100 py-1 text-sm text-blue-500"
              label="Running"
            />
          )
        }
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (param) => (
        <IconButton
          onClick={() => {
            handleViewDetails(param.row.id)
          }}
        >
          <ArrowTopRightOnSquareIcon className="h-6 w-6 hover:text-indigo-500" />
        </IconButton>
      ),
    },
  ]

  type HistoryRow = (typeof historyRows)[0]
  const historyRows = automationLogs?.map((log) => {
    return {
      id: log.id,
      timestamp: log.completedAt
        ? dayjs(log.completedAt).format('DD/MM/YYYY hh:mm:ssa')
        : dayjs(log.createdAt).format('DD/MM/YYYY hh:mm:ssa'),
      status: log.status,
      details: '-',
    }
  })

  return (
    <div className="h-1 grow">
      <DataGrid<HistoryRow>
        sx={{
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          border: 'none',
          padding: 0,
          '& .MuiDataGrid-main': {
            '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
              paddingX: '20px',
            },
          },
        }}
        localeText={{ noRowsLabel: 'This automation has no run history.' }}
        slots={{ footer: CustomFooter as GridSlots['footer'] }}
        initialState={{
          pagination: { paginationModel: { pageSize: AUTOMATION_LOG_LIMIT } },
        }}
        loading={loading}
        pageSizeOptions={[AUTOMATION_LOG_LIMIT]}
        rows={historyRows}
        columns={historyColumns}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
      />
    </div>
  )
}

export default AutomationHistoryLog
