import { FC } from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Step, StepContent, StepLabel } from '@mui/material'

interface AutomationTrackCompleteStepProps {}

const AutomationTrackCompleteStep: FC<
  AutomationTrackCompleteStepProps
> = () => {
  return (
    <Step active expanded>
      <StepLabel
        className="px-0"
        icon={<CheckCircleIcon className="h-5 w-5 ml-1 text-gray-400" />}
      >
        <p className="ml-1">
          <span className="px-3 py-1 text-gray-500 text-xl">Finish</span>
        </p>
      </StepLabel>
      <StepContent className="pb-6">
        <div className="pl-4">
          <p className="pb-2 pt-2 font-light text-gray-500 italic">
            Automation Complete
          </p>
        </div>
      </StepContent>
    </Step>
  )
}

export default AutomationTrackCompleteStep
