import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import {
  BaserowBaseDataInput,
  BaserowWorkspaces,
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
  GetBaserowTableViews,
  GetBaserowTableViewsVariables,
  UpdateAutomation,
  UpdateAutomationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import { CellAutomation } from '../AutomationCell'
import AutomationFetchLoading from '../AutomationFetchLoading'
import { AutomationBaserowData } from '../AutomationLayout'
import { FormErrors } from '../AutomationModals/CreateAutomationModal'
import {
  AutocompleteSelectedChip,
  AutocompleteSelectedOption,
} from '../AutomationRequirements/AutocompleteComponents'
import {
  GET_BASEROW_FIELDS,
  GET_BASEROW_VIEWS,
  UPDATE_AUTOMATION,
} from '../queries'
import {
  AutomationTriggerType,
  BaserowObject,
  FieldsToWatchType,
  MatchCriteriaType,
  RestrictToViewOption,
} from '../utils'

type FieldsToWatchTypeOption = {
  name: string
  type: string
  id: string
}

interface AutomationEditTriggerFormProps {
  selectedAutomation: CellAutomation
  selectedAutomationBaserowData: AutomationBaserowData
  baserowResults: BaserowWorkspaces[]
}

const getBaseSelectLabel = (baseCount: number) => {
  return !baseCount ? 'There are no Base options' : 'Select a Base'
}

const getTableSelectLabel = (
  selectedBase: BaserowObject,
  tableCount: number,
) => {
  if (!selectedBase) {
    return 'Select a Base to get Table options'
  }

  if (!tableCount) {
    return 'There are no Table options'
  }

  return 'Select a Table'
}

export const getBaseOptions = (
  baserowResults: BaserowWorkspaces[],
): BaserowObject[] => {
  // Format the Bases into SelectOption objects
  const baseSelectOptions: BaserowObject[] = baserowResults.flatMap(
    (workspace) => {
      const workspaceBases: BaserowObject[] = workspace?.databases
        .map((base) => {
          return {
            value: base.id,
            label: base.name,
            group: workspace.workspaceName,
            groupId: workspace.workspaceId,
            inUseElsewhere: base.inUseElsewhere,
          }
        })
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        )

      return workspaceBases
    },
  )

  return baseSelectOptions
}

const AutomationEditTriggerForm: FC<AutomationEditTriggerFormProps> = ({
  selectedAutomation,
  selectedAutomationBaserowData,
  baserowResults,
}) => {
  // Saving state
  const [automationIsSaving, setAutomationIsSaving] = useState<boolean>(false)

  // Table mismatch is when the saved table id is not found in any baserow results
  const [tableMismatched, setTableMismatched] = useState<boolean>(false)

  // Saved Values
  const [newAutomationWorkspaceId, setNewAutomationWorkspaceId] =
    useState<number>(selectedAutomationBaserowData?.workspace?.id)
  const [newAutomationBaseId, setNewAutomationBaseId] = useState<number>(
    selectedAutomationBaserowData?.base?.id,
  )
  const [newAutomationBaseName, setNewAutomationBaseName] = useState<string>(
    selectedAutomationBaserowData?.base?.name,
  )
  const [newAutomationTableId, setNewAutomationTableId] = useState<number>(
    selectedAutomationBaserowData?.table?.id,
  )

  const [newAutomationTriggerCreate, setNewAutomationTriggerCreate] =
    useState<boolean>(selectedAutomation?.onCreateTrigger)
  const [newAutomationTriggerUpdate, setNewAutomationTriggerUpdate] =
    useState<boolean>(selectedAutomation?.onUpdateTrigger)

  const [triggerTypeOption, setTriggerTypeOption] =
    useState<AutomationTriggerType>(
      selectedAutomation?.triggerType as AutomationTriggerType,
    )

  // Restrict to View
  const [restrictToView, setRestrictToView] = useState<RestrictToViewOption>(
    selectedAutomation?.matchCriteria['FIELD']?.restrictToView ?? null,
  )
  const [restrictToViewOptions, setRestrictToViewOptions] = useState<
    RestrictToViewOption[]
  >([])
  const [restrictToViewInput, setRestrictToViewInput] = useState<string>('')

  // Watch Fields
  const [selectedFieldsToWatch, setSelectedFieldsToWatch] = useState<
    FieldsToWatchTypeOption[]
  >(selectedAutomation?.matchCriteria['FIELD']?.fieldsToWatch ?? [])
  const [selectedFieldsToWatchInput, setSelectedFieldsToWatchInput] =
    useState<string>('')

  // Select Input Options
  const [availableBases, setAvailableBases] = useState<BaserowObject[]>([])
  const [availableTables, setAvailableTables] = useState<BaserowObject[]>([])

  const startingSelectedBase: BaserowObject = getBaseOptions(
    baserowResults,
  ).find((base) => base.value === selectedAutomationBaserowData?.base?.id)

  const startingSelectedTable: BaserowObject =
    selectedAutomationBaserowData?.table?.name &&
    selectedAutomationBaserowData?.table?.id
      ? {
          label: selectedAutomationBaserowData?.table?.name,
          value: selectedAutomationBaserowData?.table?.id,
        }
      : null

  const [availableFieldsAsOptions, setAvailableFieldsAsOptions] = useState<
    FieldsToWatchTypeOption[]
  >([])

  // Base Select Input State
  const [selectedBase, setSelectedBase] =
    useState<BaserowObject>(startingSelectedBase) // Default null was causing an extra render
  const [selectedBaseInput, setSelectedBaseInput] = useState<string>('')

  const [selectedTable, setSelectedTable] = useState<BaserowObject>(
    startingSelectedTable,
  ) // Default null was causing an extra render
  const [selectedTableInput, setSelectedTableInput] = useState<string>('')

  // Form Errors
  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: null,
    base: null,
    table: null,
  })

  const noTriggersSelected =
    !newAutomationTriggerCreate && !newAutomationTriggerUpdate

  const noFieldsSelected =
    triggerTypeOption === AutomationTriggerType.FIELD &&
    selectedFieldsToWatch?.length === 0

  const saveButtonIsDisabled = noTriggersSelected || noFieldsSelected

  const confirmAutomationReset = useConfirm()

  const [updateAutomation] = useMutation<
    UpdateAutomation,
    UpdateAutomationVariables
  >(UPDATE_AUTOMATION, {
    onCompleted: () => {
      setAutomationIsSaving(false)
      toast.success('Automation Updated')
    },
    onError: () => {
      setAutomationIsSaving(false)
      toast.error('There was a problem updating your Automation')
    },
    refetchQueries: ['FindAutomationDashboardQuery', 'GetAutomationDetails'],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const [getTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      setAvailableFieldsAsOptions(
        data.getBaserowTableFields
          .map((field) => ({
            name: field.name,
            type: field.type,
            id: String(field.id),
          }))
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          ),
      )
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [getTableViews, { loading: loadingTableViews }] = useLazyQuery<
    GetBaserowTableViews,
    GetBaserowTableViewsVariables
  >(GET_BASEROW_VIEWS, {
    onCompleted: (data) => {
      setRestrictToViewOptions(
        data.getBaserowTableViews
          .map((view) => ({
            value: view.id,
            label: view.name,
            slug: view.slug,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
          ),
      )
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const validateRequiredFields = (): boolean => {
    const errors: FormErrors = {
      table: !newAutomationTableId ? 'A trigger table must be selected' : null,
      base: !newAutomationBaseId ? 'A trigger base must be selected' : null,
      name: null,
    }

    const hasErrors = Object.values(errors).some((error) => error !== null)

    if (hasErrors) {
      setFormErrors(errors)
      return false
    } else {
      return true
    }
  }

  const handleSaveDetails = () => {
    setAutomationIsSaving(true)
    const validatedInputs = validateRequiredFields()

    const baserowData: BaserowBaseDataInput = {
      baserowBaseId: String(newAutomationBaseId),
      baserowBaseName: String(newAutomationBaseName),
      baserowTableId: String(newAutomationTableId),
    }

    if (validatedInputs) {
      // Check if the table changed from the original.
      // AND
      // IF the previous table was not set (we don't need to confirm if going from nothing to something)
      const tableHasChanged =
        Number(selectedAutomation.automationResourceId) !==
          Number(newAutomationTableId) &&
        selectedAutomation?.automationResourceId !== ''

      // Set the MatchCriteria Items
      const newAutomationMatchCriteria: MatchCriteriaType = {
        FIELD:
          triggerTypeOption === AutomationTriggerType.FIELD
            ? {
                // Use field values
                fieldsToWatch: selectedFieldsToWatch?.map((field) => {
                  return {
                    id: String(field.id),
                    name: field.name,
                    type: field.type,
                  } as unknown as FieldsToWatchType
                }),
                restrictToView: restrictToView,
              }
            : {
                // Reset values
                fieldsToWatch: [],
                restrictToView: null,
              },

        // If not exist - add it to matchCriteria - ?? {} is fallback for legacy automations
        RECORD: {
          ...selectedAutomation?.matchCriteria['RECORD'],
          // Table changed or Trigger type over rules filters
          requirements:
            triggerTypeOption === AutomationTriggerType.RECORD
              ? selectedAutomation?.matchCriteria['RECORD']?.['requirements']
              : [],
        },
        // If not exist - add it to matchCriteria - ?? {} is fallback for legacy automations
        advancedLogic: {
          ...selectedAutomation?.matchCriteria['advancedLogic'],
        },
      }

      if (tableHasChanged) {
        confirmAutomationReset({
          title: 'Changing the trigger table will reset filters and actions.',
          description:
            'Changing the trigger table will invalidate your filters and actions. They will be cleared and new filters and actions will need to be added.',
          confirmationText: 'Proceed',
        })
          .then(() => {
            // Reset the Actions and Filters because the table changed

            const clearedAction = []
            const clearedMatchCriteria: MatchCriteriaType = {
              FIELD: {
                ...newAutomationMatchCriteria.FIELD,
              },
              RECORD: {
                useViewsAsFilters: true, // default is true
                selectedView: null,
                requirements: [],
              },
              advancedLogic: {
                useAdvancedLogic: false,
                advancedLogicType: null,
                FIND: {
                  TYPE: null,
                },
              },
            }

            updateAutomation({
              variables: {
                id: selectedAutomation.id,
                inputData: {
                  active: false,
                  automationResourceId: String(selectedTable?.value),
                  onCreateTrigger: newAutomationTriggerCreate,
                  onUpdateTrigger: newAutomationTriggerUpdate,
                  triggerType: triggerTypeOption,
                  action: clearedAction,
                  matchCriteria: clearedMatchCriteria,
                },
                baserowData: baserowData,
              },
            })
          })
          .catch(() => {
            setAutomationIsSaving(false)
          })
      } else {
        updateAutomation({
          variables: {
            id: selectedAutomation.id,
            inputData: {
              triggerType: triggerTypeOption,
              automationResourceId: String(selectedTable?.value),
              onCreateTrigger: newAutomationTriggerCreate,
              onUpdateTrigger: newAutomationTriggerUpdate,
              action: selectedAutomation?.action,
              matchCriteria: newAutomationMatchCriteria,
            },
            baserowData: baserowData,
          },
        })
      }

      // if  the table id changed, include the baserow data. Else update just the trigger event types.
    } else {
      setAutomationIsSaving(false)
    }
  }

  const clearAllInputs = () => {
    setSelectedBase(null)
    setSelectedBaseInput('')
    setSelectedTable(null)
    setSelectedTableInput('')
    setTableMismatched(false)

    setNewAutomationTriggerCreate(false)
    setNewAutomationTriggerUpdate(false)

    setNewAutomationBaseId(null)
    setNewAutomationBaseName('')
    setNewAutomationTableId(null)
    setNewAutomationWorkspaceId(null)

    setSelectedFieldsToWatch([])
    setRestrictToView(null)
  }

  const handleTriggerTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    filterView: AutomationTriggerType,
  ) => {
    filterView !== null && setTriggerTypeOption(filterView)
  }

  // Update the Available Base options when data loads
  useEffect(() => {
    if (baserowResults?.length > 0) {
      // Format the Bases into SelectOption objects
      const baseSelectOptions = getBaseOptions(baserowResults)
      // Set the input options
      setAvailableBases(baseSelectOptions)

      const allBaserowTableIds = baserowResults.flatMap((workspace) => {
        const baseTableIds = workspace.databases.flatMap((base) => {
          const tableIds = base.tables.flatMap((table) => {
            return table.id
          })
          return tableIds
        })
        return baseTableIds
      })

      // Check if the saved value is included in the Baserow Data
      if (
        selectedAutomation?.automationResourceId &&
        !allBaserowTableIds.includes(
          Number(selectedAutomation.automationResourceId),
        )
      ) {
        // Saved table not found in list
        setTableMismatched(true)
      }
    } else {
      // Saved table id, but no tables were found
      if (selectedAutomationBaserowData?.table?.id) {
        setTableMismatched(true)
      }

      // Reset inputs
      clearAllInputs()
    }
  }, [baserowResults])

  // Update the Available Tables options when a Base is selected
  useEffect(() => {
    if (newAutomationBaseId) {
      const allBases = baserowResults.flatMap((workspace) => {
        const workspaceBases = workspace?.databases.map((base) => base)

        return workspaceBases
      })

      const matchedWorkspace = baserowResults.find((workspace) => {
        return (
          workspace?.databases.filter((base) => base.id === newAutomationBaseId)
            ?.length > 0
        )
      })

      const base = allBases?.find(
        (base) => base.id === Number(newAutomationBaseId),
      )

      // Save the name - we need this for the insert
      setNewAutomationBaseName(base?.name)
      setNewAutomationWorkspaceId(matchedWorkspace?.workspaceId)

      const tables: BaserowObject[] = base?.tables
        ?.map((table) => {
          return {
            value: table.id,
            label: table.name,
          }
        })
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        )

      // Check if the base change has the current selected table
      const tableIds: string[] = base?.tables.map((table) => String(table.id))

      if (!tableIds?.includes(String(newAutomationTableId))) {
        // Reset the TableId
        setNewAutomationTableId(null)
      }

      setAvailableTables(tables)
    } else {
      setAvailableTables([])
    }
  }, [newAutomationBaseId])

  // Reset mismatch status when a new table value is confirmed
  useEffect(() => {
    if (newAutomationTableId && newAutomationBaseId) {
      setTableMismatched(false)
    }
  }, [newAutomationTableId])

  // When the SelectedTable input value changes
  useEffect(() => {
    if (selectedTable) {
      // Save value
      setNewAutomationTableId(Number(selectedTable?.value))
      setFormErrors({ ...formErrors, table: null })
    } else {
      setNewAutomationTableId(null)
      setSelectedFieldsToWatch([])
      setRestrictToView(null)
      setAvailableFieldsAsOptions([])
      setRestrictToViewOptions([])
    }
  }, [selectedTable])

  // Get table fields when we have the data and we need it
  useEffect(() => {
    if (
      triggerTypeOption === AutomationTriggerType.FIELD &&
      newAutomationTableId &&
      newAutomationWorkspaceId
    ) {
      getTableFields({
        variables: {
          input: {
            tableId: newAutomationTableId?.toString(),
            workspaceId: newAutomationWorkspaceId?.toString(),
          },
        },
      })
      getTableViews({
        variables: { input: { tableId: newAutomationTableId?.toString() } },
      })
    }
  }, [triggerTypeOption, newAutomationTableId, newAutomationWorkspaceId])

  // When the SelectedBase input value changes
  useEffect(() => {
    if (selectedBase?.value) {
      // Reset table each time the base changes
      if (newAutomationBaseId !== selectedBase.value) {
        setSelectedTable(null)
      }

      // Save value
      setNewAutomationBaseId(selectedBase?.value)

      // Revalidate field
      setFormErrors({ ...formErrors, base: null })
    } else {
      setNewAutomationBaseId(null)
      setNewAutomationBaseName('')
      setNewAutomationTableId(null)
      setSelectedTable(null)
      setSelectedFieldsToWatch([])
      setRestrictToView(null)
      setAvailableFieldsAsOptions([])
      setRestrictToViewOptions([])
    }
  }, [selectedBase])

  return (
    <div className="w-full p-6">
      <p className="text-lg mb-2">Trigger Table</p>
      <div>
        <FormInputRow label="Base">
          <FormControl
            className="w-full"
            disabled={availableBases?.length === 0}
            error={formErrors.base ? true : false}
          >
            <InputLabel
              htmlFor="select-base-label"
              className="-mt-1.5 !text-gray-400 font-light text-sm"
            >
              {!selectedBase?.value &&
                !selectedBaseInput &&
                getBaseSelectLabel(availableBases?.length)}
            </InputLabel>

            <Autocomplete
              id="select-base-label"
              data-testid="select-base-label"
              size="small"
              options={availableBases}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => {
                return `${option.label} ${option.inUseElsewhere ? '(In Use)' : ''}`
              }}
              renderInput={(params) => (
                <TextField {...params} error={formErrors.base ? true : false} />
              )}
              value={selectedBase}
              onChange={(
                _event: React.ChangeEvent,
                newValue: BaserowObject,
              ) => {
                setSelectedBase(newValue)
              }}
              inputValue={selectedBaseInput}
              onInputChange={(_event, newInputValue) => {
                setSelectedBaseInput(newInputValue)
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value &&
                option.label === value.label &&
                option.group === value.group
              }
              disabled={availableBases?.length === 0 || automationIsSaving}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  disabled={option.inUseElsewhere}
                  style={{ opacity: option.inUseElsewhere ? 0.5 : 1 }}
                >
                  <Typography variant="body1">
                    {option.label}
                    {option.inUseElsewhere ? '(Used by Another Client)' : ''}
                  </Typography>
                </MenuItem>
              )}
              renderGroup={(params) => (
                <li {...params}>
                  <ListSubheader
                    className={'border-y bg-gray-50 text-gray-600'}
                  >
                    {params.group}
                  </ListSubheader>
                  <ul>{params.children}</ul>
                </li>
              )}
            />
            <FormHelperText>{formErrors.base}</FormHelperText>
          </FormControl>
        </FormInputRow>

        <FormInputRow label="Table">
          <FormControl
            className="w-full"
            disabled={availableTables?.length === 0 || !selectedBase?.value}
            error={formErrors.table ? true : false}
          >
            <InputLabel
              htmlFor="select-table-label"
              className="-mt-1.5 !text-gray-400 font-light text-sm"
            >
              {!selectedTable?.value &&
                !selectedTableInput &&
                getTableSelectLabel(selectedBase, availableTables?.length)}
            </InputLabel>

            <Autocomplete
              id="select-table-label"
              size="small"
              data-testid="select-table-label"
              options={availableTables}
              groupBy={(option) => option.group}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formErrors.table ? true : false}
                />
              )}
              value={selectedTable}
              onChange={(
                _event: React.ChangeEvent,
                newValue: BaserowObject,
              ) => {
                setSelectedTable(newValue)
                // Reset these when selecting a new table
                setSelectedFieldsToWatch([])
                setRestrictToView(null)
                setRestrictToViewOptions([])
              }}
              inputValue={selectedTableInput}
              onInputChange={(_event, newInputValue) => {
                setSelectedTableInput(newInputValue)
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value && option.label === value.label
              }
              disabled={availableTables?.length === 0 || automationIsSaving}
            />

            <FormHelperText>{formErrors.table}</FormHelperText>
          </FormControl>
        </FormInputRow>

        {tableMismatched && (
          <p className="text-sm font-light text-amber-600 bg-amber-50 border border-amber-600 rounded p-4">
            The original table in this automation has either been deleted or you
            no longer have access. Please select a new table, or click cancel
            and rectify the issue in Baserow.
          </p>
        )}
      </div>
      <div className="mt-6">
        <p className="text-lg mb-2">Trigger Type</p>
        <div className="flex gap-4 items-start justify-between w-full">
          <div>
            {triggerTypeOption === AutomationTriggerType.RECORD && (
              <p className="text-gray-400 text-sm font-light">
                Process automation on record create or update.
              </p>
            )}
            {triggerTypeOption === AutomationTriggerType.FIELD && (
              <p className="text-gray-400 text-sm font-light">
                Process automation on record create or update, and when a field
                changes in a record.
              </p>
            )}
          </div>
          <ToggleButtonGroup
            color="primary"
            value={triggerTypeOption}
            exclusive
            onChange={handleTriggerTypeChange}
          >
            <ToggleButton
              disableRipple
              className={`w-32 py-1 text-gray-300 gap-3 border-gray-400 ml-0 ${
                triggerTypeOption === AutomationTriggerType.RECORD &&
                '!bg-indigo-100 !text-indigo-600 border-indigo-600'
              }`}
              value={AutomationTriggerType.RECORD}
            >
              <CheckCircleIcon className="w-5 h-5" />
              <span className="pt-0.5">{AutomationTriggerType.RECORD}</span>
            </ToggleButton>
            <ToggleButton
              disableRipple
              className={`w-32 py-1 text-gray-300 gap-3 border-gray-400 ${
                triggerTypeOption === AutomationTriggerType.FIELD &&
                '!bg-indigo-100 !text-indigo-600 border-indigo-600 !border-l-indigo-600'
              }`}
              value={AutomationTriggerType.FIELD}
            >
              <CheckCircleIcon className="w-5 h-5" />
              <span className="pt-0.5">{AutomationTriggerType.FIELD}</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="mt-6">
        <p className="text-lg mb-3">Trigger Events</p>

        <div>
          <FormControl
            required
            error={noTriggersSelected}
            component="fieldset"
            variant="standard"
            className="w-full"
          >
            <FormGroup className="w-full flex flex-col gap-3">
              <FormControlLabel
                className={`w-full border rounded text-md p-1 py-2 m-0 ${
                  newAutomationTriggerCreate && 'border-indigo-400'
                }`}
                control={
                  <Checkbox
                    disabled={automationIsSaving}
                    disableRipple
                    checked={newAutomationTriggerCreate}
                    onChange={(event) => {
                      setNewAutomationTriggerCreate(event.target.checked)
                    }}
                    name="onCreate"
                    className="bg-transparent"
                    icon={
                      <CheckCircleIcon className="w-6 h-6 mr-2 text-gray-200" />
                    }
                    checkedIcon={
                      <CheckCircleIcon className="w-6 h-6 mr-2 text-indigo-500" />
                    }
                  />
                }
                label={
                  <span className="flex flex-col">
                    Record Create
                    <span className="text-gray-400 text-sm font-light">
                      {` Trigger when a record gets created${
                        selectedTable?.value
                          ? ' in table ' + selectedTable?.label
                          : ''
                      }.`}
                    </span>
                  </span>
                }
              />
              <FormControlLabel
                className={`w-full border rounded text-md p-1 py-2 m-0 ${
                  newAutomationTriggerUpdate && 'border-indigo-400'
                }`}
                control={
                  <Checkbox
                    disabled={automationIsSaving}
                    disableRipple
                    checked={newAutomationTriggerUpdate}
                    onChange={(event) => {
                      setNewAutomationTriggerUpdate(event.target.checked)
                    }}
                    name="onUpdate"
                    icon={
                      <CheckCircleIcon className="w-6 h-6 mr-2 text-gray-200" />
                    }
                    checkedIcon={
                      <CheckCircleIcon className="w-6 h-6 mr-2 text-indigo-500" />
                    }
                  />
                }
                label={
                  <span className="flex flex-col">
                    Record Update
                    <span
                      className="text-gray-400 text-sm font-light"
                      data-testid="trigger-on-record-update"
                    >
                      {` Trigger when a record gets updated${
                        selectedTable?.value
                          ? ' in table ' + selectedTable?.label
                          : ''
                      }.`}
                    </span>
                  </span>
                }
              />
            </FormGroup>
            <FormHelperText>
              {noTriggersSelected && (
                <p className="!text-sm mt-2">
                  At least one event must be selected for the automation to
                  trigger.
                </p>
              )}
            </FormHelperText>
          </FormControl>
        </div>

        {triggerTypeOption === AutomationTriggerType.FIELD && (
          <div className="mt-4">
            {(loadingTableFields || loadingTableViews) && (
              <div className="pt-20">
                <AutomationFetchLoading text="Fetching Table Data" />
              </div>
            )}
            {!loadingTableFields && !loadingTableViews && (
              <>
                <FormInputRow label="Fields to Watch">
                  <FormControl
                    className="w-full"
                    disabled={availableFieldsAsOptions?.length === 0}
                    error={selectedFieldsToWatch?.length === 0}
                  >
                    <InputLabel
                      htmlFor="select-create-Field"
                      className="-mt-1.5 !text-gray-400 font-light text-sm"
                    >
                      {availableFieldsAsOptions?.length === 0 &&
                        'No Fields available'}
                    </InputLabel>
                    <Autocomplete
                      disabled={availableFieldsAsOptions?.length === 0}
                      value={selectedFieldsToWatch}
                      onChange={(
                        _event: React.ChangeEvent,
                        newValue: FieldsToWatchTypeOption[],
                      ) => {
                        setSelectedFieldsToWatch(newValue)
                      }}
                      inputValue={selectedFieldsToWatchInput}
                      onInputChange={(_event, newInputValue) => {
                        setSelectedFieldsToWatchInput(newInputValue)
                      }}
                      options={availableFieldsAsOptions}
                      size="small"
                      id="select-trigger-field"
                      multiple
                      isOptionEqualToValue={(option, value) => {
                        return (
                          option?.name === value?.name &&
                          option?.type === value?.type
                        )
                      }}
                      renderTags={(
                        value: readonly FieldsToWatchTypeOption[],
                        getTagProps,
                      ) =>
                        value.map(
                          (option: FieldsToWatchTypeOption, index: number) => (
                            <AutocompleteSelectedChip
                              key={option.name}
                              option={{
                                value: option.name,
                                label: option.name,
                              }}
                              props={getTagProps}
                              index={index}
                            />
                          ),
                        )
                      }
                      filterOptions={(options) => {
                        return options.filter((option) =>
                          option.name
                            .toLowerCase()
                            .includes(selectedFieldsToWatchInput.toLowerCase()),
                        )
                      }}
                      renderOption={(
                        props,
                        option: FieldsToWatchTypeOption,
                      ) => (
                        <AutocompleteSelectedOption
                          props={props}
                          option={{
                            value: option.name,
                            label: option.name,
                            type: option.type,
                          }}
                          useFieldIcons
                        />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={selectedFieldsToWatch?.length === 0}
                          placeholder={
                            availableFieldsAsOptions?.length &&
                            selectedFieldsToWatch?.length === 0
                              ? 'Select a Field'
                              : ''
                          }
                        />
                      )}
                    />
                    <FormHelperText>
                      {availableFieldsAsOptions?.length &&
                      selectedFieldsToWatch?.length === 0
                        ? 'Select a Field'
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </FormInputRow>
                <FormInputRow label="Restrict to View">
                  <FormControl
                    className="w-full"
                    disabled={restrictToViewOptions?.length === 0}
                  >
                    <InputLabel
                      htmlFor="select-create-Value"
                      className="-mt-1.5 !text-gray-400 font-light text-sm"
                    >
                      {restrictToViewOptions?.length === 0 &&
                        'No Values available'}
                    </InputLabel>
                    <Autocomplete
                      value={restrictToView}
                      disabled={restrictToViewOptions?.length === 0}
                      onChange={(
                        _event: React.ChangeEvent,
                        newValue: RestrictToViewOption,
                      ) => {
                        setRestrictToView(newValue)
                      }}
                      inputValue={restrictToViewInput}
                      onInputChange={(_event, newInputValue) => {
                        setRestrictToViewInput(newInputValue)
                      }}
                      options={restrictToViewOptions}
                      size="small"
                      id="select-create-Value"
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderOption={(props, option: RestrictToViewOption) => (
                        <AutocompleteSelectedOption
                          props={props}
                          option={option}
                        />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            restrictToViewOptions?.length > 0 &&
                            'Select a Value'
                          }
                        />
                      )}
                    />
                  </FormControl>
                </FormInputRow>
                <p className="text-gray-400 text-sm font-light pt-4">
                  Triggering an automation by Field or View will over rule any
                  filters. Your existing filters will be cleared.
                </p>
              </>
            )}
          </div>
        )}
      </div>
      <div className="flex items-center justify-start pt-4 mt-4">
        <Button
          disabled={
            saveButtonIsDisabled || loadingTableFields || loadingTableViews
          }
          fullWidth={false}
          onClick={handleSaveDetails}
          loading={automationIsSaving}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default AutomationEditTriggerForm
