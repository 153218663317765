import React, { FC, useEffect, useState } from 'react'

import CustomActionComponent from 'src/components/Automations/AutomationActions/CustomActionComponent'
import {
  BaserowField,
  EnrichedActionItemType,
  FilterType,
} from 'src/components/Automations/utils'

interface CustomInputComponentListProps {
  selectedInput: FilterType
  onUpdate: (updatedReq: EnrichedActionItemType | null) => void
  actionStep: EnrichedActionItemType
  availableFields: BaserowField[]
  triggerTableFields?: BaserowField[]
}
export const CustomActionComponentList: FC<CustomInputComponentListProps> = ({
  selectedInput,
  onUpdate,
  actionStep,
  availableFields,
  triggerTableFields,
}) => {
  const [paramKeys, setParamKeys] = useState([])
  const [selectedField, setSelectedField] = useState(null)

  // This function pushes the state up again to save it
  const handleParamChange = (
    paramKey: string,
    newValue: any,
    newValueLabel: string,
    copyType?: string,
  ) => {
    const updatedActionStep = {
      ...actionStep,
      inputType: {
        ...selectedInput,
        params: {
          ...selectedInput.params,
          [paramKey]: {
            ...selectedInput.params[paramKey],
            value: newValue,
            valueLabel: newValueLabel,
            ...(copyType ? { copyType: copyType } : {}),
          },
        },
      },
    }

    onUpdate(updatedActionStep)
  }

  useEffect(() => {
    // Available fields changed - either on mount or the target table changed

    // Find what?
    const selectedField = availableFields.find(
      (field) => field.name === actionStep.fieldName,
    )

    setSelectedField(selectedField ?? null)

    if (selectedInput) {
      const paramKeys = Object.keys(selectedInput.params)
      setParamKeys(paramKeys)
    }
  }, [availableFields, actionStep])

  return (
    <>
      {paramKeys?.map((paramKey) => {
        return (
          <CustomActionComponent
            key={paramKey}
            paramKey={paramKey}
            paramValue={selectedInput.params[paramKey]}
            handleChange={handleParamChange}
            allParams={selectedInput.params}
            selectOptions={selectedField?.select_options}
            actionTableFields={availableFields}
            triggerTableFields={triggerTableFields}
            collaborators={selectedField?.collaboratorData}
          />
        )
      })}
    </>
  )
}

export default CustomActionComponentList
