import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import LinkedFieldFilters from 'src/components/Automations/AdvancedLogic/LinkedFieldFilters'
import LinkedFieldSelector from 'src/components/Automations/AdvancedLogic/LinkedFieldSelector'
import LogicTypeSelector from 'src/components/Automations/AdvancedLogic/LogicTypeSelector'
import RecordSelectorReferenceField from 'src/components/Automations/AdvancedLogic/RecordSelectorReferenceField'
import RecordSelectorType from 'src/components/Automations/AdvancedLogic/RecordSelectorType'
import { AutomationBaserowData } from 'src/components/Automations/AutomationLayout'

import { SelectOption } from '../AutomationRequirements/AutocompleteComponents'
import { GET_BASEROW_FIELDS } from '../queries'
import {
  BaserowField,
  BaserowObject,
  EnrichedActionType,
  FindSelectorType,
  LinkedFieldForFindType,
  RequirementGroupType,
  SelectedLinkFieldRefineValueOption,
} from '../utils'

interface AutomationEditActionFormProps {
  setRequirementsInvalid: Dispatch<SetStateAction<boolean>>
  selectedWorkspace: BaserowObject
  linkedTableOptions: LinkedFieldForFindType[]
  setSelectedLinkedFieldForFindingSelector: Dispatch<
    SetStateAction<SelectOption>
  >
  selectedLinkedFieldForFindingSelector: SelectOption
  setAdvancedLogicFilterRequirements: Dispatch<
    SetStateAction<RequirementGroupType[]>
  >
  advancedLogicFilterRequirements: RequirementGroupType[]
  setSelectedLinkFieldRefineValue: Dispatch<
    SetStateAction<SelectedLinkFieldRefineValueOption>
  >
  selectedLinkFieldRefineValue: SelectedLinkFieldRefineValueOption
  setSelectedLinkedField: Dispatch<SetStateAction<LinkedFieldForFindType>>
  selectedLinkedField: LinkedFieldForFindType
  selectedAutomationBaserowData: AutomationBaserowData
}

const AdvancedLogicForm: FC<AutomationEditActionFormProps> = ({
  setRequirementsInvalid,
  selectedWorkspace,
  linkedTableOptions,
  setSelectedLinkedFieldForFindingSelector,
  selectedLinkedFieldForFindingSelector,
  setAdvancedLogicFilterRequirements,
  advancedLogicFilterRequirements,
  setSelectedLinkFieldRefineValue,
  selectedLinkFieldRefineValue,
  setSelectedLinkedField,
  selectedLinkedField,
  selectedAutomationBaserowData,
}) => {
  // Filter Requirements
  const [actionItems, setActionItems] = useState<EnrichedActionType[]>([])

  // The list of fields in the table of the linked field. Ie, the foreign table fields.
  const [linkedFieldListOfFields, setLinkedFieldListOfFields] = useState<
    BaserowField[]
  >([])

  // When the user selects a "Selector" this ie first created, last created, etc, the user needs to select a field that houses the calculated date field that is used to determine this. This state holds the list of fields that can be used for this
  const [
    linkedFieldForFindingSelectorOptions,
    setLinkedFieldForFindingSelectorOptions,
  ] = useState<SelectOption[]>([])

  const [
    isLoadingLinkedFieldForFindingSelector,
    setIsLoadingLinkedFieldForFindingSelector,
  ] = useState<boolean>(false)

  const handleClearActionItems = () => {
    updateActionItems(
      actionItems?.map((item) => {
        return { ...item, data: [] }
      }),
    )
  }

  const updateActionItems = (updatedActionItems: EnrichedActionType[]) => {
    setActionItems(updatedActionItems)
  }

  const getBaserowTableData = async (workspaceId: string, tableId: string) => {
    const { data } = await GetTableFields({
      variables: {
        input: {
          tableId: tableId,
          workspaceId: workspaceId,
        },
      },
    })
    return data
  }

  const [GetTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      return data.getBaserowTableFields
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
      return []
    },
  })

  useEffect(() => {
    const fetchAndAssignLinkedFieldData = async () => {
      setIsLoadingLinkedFieldForFindingSelector(true)
      const data = await getBaserowTableData(
        selectedWorkspace.value?.toString(),
        selectedLinkedField.linkedToTable.toString(),
      )

      setLinkedFieldListOfFields(data.getBaserowTableFields)
      setIsLoadingLinkedFieldForFindingSelector(false)
    }

    if (selectedLinkedField && selectedWorkspace) {
      fetchAndAssignLinkedFieldData()
    }
  }, [selectedLinkedField, selectedWorkspace])

  useEffect(() => {
    const fetchAndAssignSelectorLinkedFieldData = async () => {
      setIsLoadingLinkedFieldForFindingSelector(true)
      const data = await getBaserowTableData(
        selectedWorkspace.value?.toString(),
        selectedLinkedField.linkedToTable.toString(),
      )

      const filterType = [
        FindSelectorType.FIRST_CREATED,
        FindSelectorType.LAST_CREATED,
      ].includes(selectedLinkFieldRefineValue.value)
        ? 'created_on'
        : [
              FindSelectorType.FIRST_MODIFIED,
              FindSelectorType.LAST_MODIFIED,
            ].includes(selectedLinkFieldRefineValue.value)
          ? 'last_modified'
          : null

      setLinkedFieldForFindingSelectorOptions(
        data.getBaserowTableFields
          .filter((field) => field.type === filterType)
          .map((field) => {
            return {
              value: field.id.toString(),
              label: field.name,
            }
          }),
      )
      setIsLoadingLinkedFieldForFindingSelector(false)
    }

    if (selectedLinkedField && selectedWorkspace) {
      fetchAndAssignSelectorLinkedFieldData()
    }
  }, [selectedLinkFieldRefineValue])

  return (
    <div className="mb-6 border-b pb-4">
      {/* Step 1: Select the logic type which is FIND only for now*/}
      <LogicTypeSelector />

      {/* Step 2: Select the linked field in the trigger record used to find the records we will be working with */}
      <LinkedFieldSelector
        handleClearActionItems={handleClearActionItems}
        setAdvancedLogicFilterRequirements={setAdvancedLogicFilterRequirements}
        setSelectedLinkFieldRefineValue={setSelectedLinkFieldRefineValue}
        setSelectedLinkedField={setSelectedLinkedField}
        selectedLinkedField={selectedLinkedField}
        linkedTableOptions={linkedTableOptions}
      />

      {selectedLinkedField && (
        <>
          {/* Step 3: Filter the linked records down if the user so wishes*/}
          <LinkedFieldFilters
            setRequirementsInvalid={setRequirementsInvalid}
            linkedFieldListOfFields={linkedFieldListOfFields}
            loadingTableFields={loadingTableFields}
            advancedLogicFilterRequirements={advancedLogicFilterRequirements}
            setAdvancedLogicFilterRequirements={
              setAdvancedLogicFilterRequirements
            }
            selectedAutomationBaserowData={selectedAutomationBaserowData}
          />

          {/* Step 4: Refine the records down to a single record by things such as last modified, first created etc*/}
          <RecordSelectorType
            handleClearActionItems={handleClearActionItems}
            setSelectedLinkedFieldForFindingSelector={
              setSelectedLinkedFieldForFindingSelector
            }
            setSelectedLinkFieldRefineValue={setSelectedLinkFieldRefineValue}
            selectedLinkFieldRefineValue={selectedLinkFieldRefineValue}
          />

          {[
            FindSelectorType.LAST_MODIFIED,
            FindSelectorType.LAST_CREATED,
            FindSelectorType.FIRST_CREATED,
            FindSelectorType.FIRST_MODIFIED,
          ].includes(selectedLinkFieldRefineValue?.value) && (
            <RecordSelectorReferenceField
              linkedTableOptions={linkedTableOptions}
              handleClearActionItems={handleClearActionItems}
              linkedFieldForFindingSelectorOptions={
                linkedFieldForFindingSelectorOptions
              }
              isLoadingLinkedFieldForFindingSelector={
                isLoadingLinkedFieldForFindingSelector
              }
              selectedLinkedFieldForFindingSelector={
                selectedLinkedFieldForFindingSelector
              }
              setSelectedLinkedFieldForFindingSelector={
                setSelectedLinkedFieldForFindingSelector
              }
            />
          )}
        </>
      )}
    </div>
  )
}

export default AdvancedLogicForm
