import { FC, useEffect, useState } from 'react'

import {
  automationType,
  CreateAutomation,
  CreateAutomationInput,
  CreateAutomationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  AutomationTriggerType,
  MatchCriteriaType,
} from 'src/components/Automations/utils'

import Modal from '../../Modal/Modal'
import { CellAutomation } from '../AutomationCell'
import CreateNewAutomationForm from '../CreateNewAutomationForm'
import { CREATE_AUTOMATION } from '../queries'

export interface FormErrors {
  name: string
  base?: string
  table?: string
}

interface CreateAutomationModalProps {
  openModal: boolean
  handleClose: (param: boolean) => void
  createAutomationInCategoryId: number
  setSelectedAutomation: any
}

const CreateAutomationModal: FC<CreateAutomationModalProps> = ({
  openModal = false,
  handleClose,
  createAutomationInCategoryId,
  setSelectedAutomation,
}) => {
  // Form States
  const [newAutomationName, setNewAutomationName] = useState<string>('')
  const [newAutomationDescription, setNewAutomationDescription] =
    useState<string>('')

  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: null,
  })

  // Modal Loading State
  const [createAutomationIsLoading, setCreateAutomationIsLoading] =
    useState<boolean>(false)

  const [createAutomation] = useMutation<
    CreateAutomation,
    CreateAutomationVariables
  >(CREATE_AUTOMATION, {
    onCompleted: (data) => {
      toast.success('Automation Created')
      handleClose(false)
      setSelectedAutomation(data.createAutomation as CellAutomation)
      setCreateAutomationIsLoading(false)
    },
    onError: () => {
      toast.error('There was a problem creating your Automation.')
      setCreateAutomationIsLoading(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const validateRequiredFields = (): boolean => {
    const errors: FormErrors = {
      name: !newAutomationName ? 'Automation name cannot be empty' : null,
    }

    const hasErrors = Object.values(errors).some((error) => error !== null)

    if (hasErrors) {
      setFormErrors(errors)
      return false
    } else {
      return true
    }
  }

  const handleAutomationCreate = async () => {
    setCreateAutomationIsLoading(true)

    const inputsValidated = validateRequiredFields()

    if (inputsValidated) {
      const matchCriteria: MatchCriteriaType = {
        RECORD: {
          useViewsAsFilters: null,
          selectedView: null,
          requirements: [],
        },
        FIELD: {
          fieldsToWatch: [],
          restrictToView: null,
        },
        advancedLogic: {
          useAdvancedLogic: false,
          advancedLogicType: null,
          FIND: {
            linkedFieldData: null,
            filters: [],
            TYPE: null,
          },
        },
      }

      const inputData: CreateAutomationInput = {
        name: newAutomationName,
        description: newAutomationDescription,
        onCreateTrigger: false,
        onDeleteTrigger: false,
        onUpdateTrigger: false,
        triggerType: AutomationTriggerType.RECORD,
        matchCriteria,
        action: [],
        automationResourceId: '',
        automationResourceType: 'BASEROW' as automationType,
        automationCategoryId: createAutomationInCategoryId,
      }

      await createAutomation({
        variables: {
          inputData,
        },
      })
    } else {
      setCreateAutomationIsLoading(false)
    }
  }

  useEffect(() => {
    setCreateAutomationIsLoading(false)
  }, [openModal])

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      dialogClassName={'max-w-2xl'}
      childClassName="p-6"
      title="Create New Automation"
      footerVisible
      loading={createAutomationIsLoading}
      confirmText="Create Automation"
      onConfirm={handleAutomationCreate}
      cancelText="Cancel"
      onCancel={() => handleClose(false)}
    >
      <CreateNewAutomationForm
        setNewAutomationName={setNewAutomationName}
        setNewAutomationDescription={setNewAutomationDescription}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        createAutomationIsLoading={createAutomationIsLoading}
      />
    </Modal>
  )
}

export default CreateAutomationModal
