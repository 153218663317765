import { FC } from 'react'

import { CubeTransparentIcon } from '@heroicons/react/24/outline'

const AutomationTrackEmpty: FC = () => {
  return (
    <div
      className={'grow  w-full grid place-items-center '}
      style={{
        transitionTimingFunction: 'ease-in-out',
        transitionDuration: '300ms',
      }}
    >
      <div className="flex flex-col gap-4 justify-center items-center">
        <CubeTransparentIcon className="w-20 h-20 text-gray-400" />
        <p className="text-gray-400">Nothing Selected</p>
      </div>
    </div>
  )
}

export default AutomationTrackEmpty
