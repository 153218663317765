import React, { Dispatch, FC, SetStateAction } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import { SelectOption } from '../AutomationRequirements/AutocompleteComponents'
import { LinkedFieldForFindType } from '../utils'

interface RecordSelectorReferenceFieldProps {
  linkedTableOptions: LinkedFieldForFindType[]
  handleClearActionItems: () => void
  linkedFieldForFindingSelectorOptions: SelectOption[]
  isLoadingLinkedFieldForFindingSelector: boolean
  selectedLinkedFieldForFindingSelector: SelectOption
  setSelectedLinkedFieldForFindingSelector: Dispatch<
    SetStateAction<SelectOption>
  >
}

const RecordSelectorReferenceField: FC<RecordSelectorReferenceFieldProps> = ({
  linkedTableOptions,
  handleClearActionItems,
  linkedFieldForFindingSelectorOptions,
  isLoadingLinkedFieldForFindingSelector,
  selectedLinkedFieldForFindingSelector,
  setSelectedLinkedFieldForFindingSelector,
}) => {
  // The selected field that is used to determine the selector. For example, if the selector is first created, this field is the field that holds the created date

  return (
    <FormInputRow label="Calculated From Field">
      <FormControl
        className="w-full"
        disabled={linkedTableOptions?.length === 0}
        error={!selectedLinkedFieldForFindingSelector?.value}
      >
        <Autocomplete
          loading={isLoadingLinkedFieldForFindingSelector}
          disabled={linkedTableOptions?.length === 0}
          value={selectedLinkedFieldForFindingSelector}
          options={linkedFieldForFindingSelectorOptions}
          onChange={(_event: React.ChangeEvent, newValue: SelectOption) => {
            setSelectedLinkedFieldForFindingSelector(newValue)
            handleClearActionItems()
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value
          }}
          id="select-Field-Option"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                linkedTableOptions?.length > 0 && 'Select a Linked Field'
              }
              error={!selectedLinkedFieldForFindingSelector}
            />
          )}
          fullWidth
          size="small"
          disableClearable
        />
        <FormHelperText>
          {!selectedLinkedFieldForFindingSelector?.value && 'Refine Records'}
        </FormHelperText>
      </FormControl>
    </FormInputRow>
  )
}

export default RecordSelectorReferenceField
