import { Tooltip } from '@mui/material'
import { GridAddIcon } from '@mui/x-data-grid'
import { useRichTextEditorContext } from 'mui-tiptap'

export function MenuButtonAddColumn() {
  const editor = useRichTextEditorContext()

  if (!editor) {
    return null
  }

  return (
    <Tooltip title="Add Column" arrow placement="top">
      <button
        type="button"
        onClick={() => editor.chain().focus().addColumnAfter().run()}
        disabled={!editor.can().addColumnAfter()}
        className="ml-1"
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          margin: '0 5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: editor.can().addColumnAfter() ? 'pointer' : 'not-allowed',
        }}
      >
        <GridAddIcon
          style={{
            fontSize: '18px',
            color: editor.can().addColumnAfter() ? '#888' : '#ccc',
          }}
        />
      </button>
    </Tooltip>
  )
}
