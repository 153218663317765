import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import { Drawer } from '@mui/material'
import { useDebounceValue } from 'usehooks-ts'

import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'

import { CellAutomation, CellAutomationCategory } from '../AutomationCell'
import AutomationCategoryModal, {
  AutomationCategoryOption,
  ModalState,
} from '../AutomationModals/AutomationCategoryModal'

import AutomationFolderList from './AutomationFolderList'

// Recursive Function
const filterCategoriesBySearchTerm = (
  searchTerm: string,
  automationCategories: CellAutomationCategory[],
  depth: number = 0,
): CellAutomationCategory[] => {
  // Early exit if possible
  if (searchTerm?.length === 0) return automationCategories

  // Maximum depth.
  if (depth >= 5) return []

  // basically we want to go into each sub cat recursively, and return automations that match the search input
  const returnCategories = automationCategories.map((category) => {
    let subCategoriesFiltered = []
    if (category?.childCategories) {
      subCategoriesFiltered = filterCategoriesBySearchTerm(
        searchTerm,
        category?.childCategories as CellAutomationCategory[],
        depth + 1,
      )
    }

    let returnAutomations = []
    if (category.automations?.length > 0) {
      returnAutomations = category?.automations?.filter((automation) =>
        automation.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    }

    return {
      ...category,
      childCategories: subCategoriesFiltered,
      automations: returnAutomations,
    }
  })

  return returnCategories
}

interface AutomationListSidebarProps {
  automationCategories: CellAutomationCategory[]
  uncategorisedAutomations: CellAutomationCategory[]
  showFolderList: boolean
  selectedAutomation: CellAutomation
  setSelectedAutomation: Dispatch<SetStateAction<CellAutomation>>
  handleCreateAutomationModalOpen: (categoryId: number) => void
}

const AutomationListSidebar: FC<AutomationListSidebarProps> = ({
  showFolderList = false,
  automationCategories,
  uncategorisedAutomations,
  selectedAutomation,
  setSelectedAutomation,
  handleCreateAutomationModalOpen,
}) => {
  const drawerWidth = 450

  const [categoryList, setCategoryList] =
    useState<CellAutomationCategory[]>(automationCategories)
  const [uncategorisedList, setUncategorisedList] = useState<
    CellAutomationCategory[]
  >(uncategorisedAutomations)

  const [categoryModalOpen, setCategoryModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState<ModalState>(ModalState.CREATE)

  const [editCategory, setEditCategory] = useState<CellAutomationCategory>(null)

  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedValue] = useDebounceValue<string>(searchValue, 500)

  const disableDragAndDrop = debouncedValue?.length > 0

  const automationCategoryOptions: AutomationCategoryOption[] =
    automationCategories
      .map((category) => {
        // This will only return top level categories as options for being a parent.
        return { id: category.id, label: category.name }
      })
      .filter((category) => category.id !== editCategory?.id)

  const allAutomations = automationCategories
    .reduce((accumulator, current) => {
      const childCategories = current?.childCategories

      accumulator.push(current)
      accumulator.push(childCategories)

      return accumulator
    }, [])
    .flat()

  const handleEditCategorySelection = (id: number) => {
    // Find Automation
    const editAutomation = allAutomations.find((category) => category.id === id)
    setEditCategory(editAutomation ?? null)

    // Start Editing
    setModalMode(ModalState.EDIT)
    setCategoryModalOpen(true)
  }

  useEffect(() => {
    setCategoryList(
      filterCategoriesBySearchTerm(debouncedValue, automationCategories),
    )
    setUncategorisedList(
      filterCategoriesBySearchTerm(debouncedValue, uncategorisedAutomations),
    )
  }, [automationCategories, debouncedValue, uncategorisedAutomations])

  return (
    <>
      <Drawer
        elevation={0}
        className="!ease-in-out !duration-300 relative z-[0]"
        sx={{
          width: showFolderList ? drawerWidth : 0,
          flexShrink: 0,
          position: 'relative',
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: '300ms',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            position: 'absolute',
            boxSizing: 'border-box',
            transitionTimingFunction: 'ease-in-out!important',
            transitionDuration: '300ms!important',
          },
        }}
        variant="persistent"
        anchor="left"
        open={showFolderList}
      >
        <div className="sticky top-0 bg-white z-10 ">
          <div className="p-4 border-b h-[75px] flex items-center">
            <SearchField
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Search Automations..."
            />
          </div>
        </div>
        <div className="flex flex-col p-4 pb-0 justify-between h-full relative">
          <AutomationFolderList
            grid={16}
            automationCategories={categoryList}
            uncategorisedAutomations={uncategorisedList}
            disableDragAndDrop={disableDragAndDrop}
            handleEditCategorySelection={handleEditCategorySelection}
            selectedAutomation={selectedAutomation}
            setSelectedAutomation={setSelectedAutomation}
            handleCreateAutomationModalOpen={handleCreateAutomationModalOpen}
          />
          <div className="sticky bottom-0 pb-4 pt-4 w-full bg-white">
            <Button
              onClick={() => {
                setModalMode(ModalState.CREATE)
                setCategoryModalOpen(true)
              }}
              startIcon={<PlusIcon className="w-4 h-4" />}
              buttonDataTestId="add-automation-category"
            >
              Add Category
            </Button>
          </div>
        </div>
      </Drawer>
      <AutomationCategoryModal
        mode={modalMode}
        openModal={categoryModalOpen}
        editCategory={editCategory}
        automationCategoryOptions={automationCategoryOptions}
        handleClose={() => {
          setEditCategory(null)
          setCategoryModalOpen(false)
        }}
      />
    </>
  )
}

export default AutomationListSidebar
