import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  Autocomplete,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  BaserowField,
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
  GetBaserowTableViews,
  GetBaserowTableViewsVariables,
  UpdateAutomation,
  UpdateAutomationVariables,
} from 'types/graphql'
import { v4 as uuidv4 } from 'uuid'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button/Button'

import { CellAutomation } from '../AutomationCell'
import AutomationFetchLoading from '../AutomationFetchLoading'
import { AutomationBaserowData } from '../AutomationLayout'
import RequirementComponent from '../AutomationRequirements/RequirementComponent'
import RequirementGroupComponent from '../AutomationRequirements/RequirementGroupComponent'
import {
  GET_BASEROW_FIELDS,
  GET_BASEROW_VIEWS,
  UPDATE_AUTOMATION,
} from '../queries'
import { MatchCriteriaType, RequirementGroupType } from '../utils'

import { validateRequirements } from './utils'

export enum AutomationFilterType {
  FIELD = 'Fields',
  VIEW = 'Views',
}

export type DataValidationResultType = {
  success: boolean
  details?: string
}

interface BaserowViewOption {
  value: string
  label: string
  slug: string
}

interface FormErrors {
  filterType: string
  view: string
  filter: string
}

interface AutomationEditFilterFormProps {
  selectedAutomation: CellAutomation
  selectedAutomationBaserowData: AutomationBaserowData
}

const AutomationEditFilterForm: FC<AutomationEditFilterFormProps> = ({
  selectedAutomation,
  selectedAutomationBaserowData,
}) => {
  // Baserow States
  const [baserowTableFields, setBaserowTableFields] = useState<BaserowField[]>(
    [],
  )

  // Saving State
  const [automationIsSaving, setAutomationIsSaving] = useState<boolean>(false)
  const [requirementsInvalid, setRequirementsInvalid] = useState<boolean>(false)

  // Field Input States
  const [formErrors, setFormErrors] = useState<FormErrors>({
    filterType: null,
    view: null,
    filter: null,
  })

  // Select View
  const [selectedView, setSelectedView] = useState<BaserowViewOption>(
    selectedAutomation?.matchCriteria['RECORD']?.['selectedView'] ?? null,
  )
  const [viewInputValue, setViewInputValue] = useState<string>('')
  const [availableViews, setAvailableViews] = useState<BaserowViewOption[]>([])

  // Set default to VIEWS
  const defaultFilterType =
    selectedAutomation?.matchCriteria['RECORD']?.['useViewsAsFilters'] ??
    AutomationFilterType.VIEW

  // Toggle Button for Filter Type
  const [filterType, setFilterType] = useState<AutomationFilterType>(
    defaultFilterType ? AutomationFilterType.VIEW : AutomationFilterType.FIELD,
  )

  // Filter Requirements
  const [requirements, setRequirements] = useState<RequirementGroupType[]>(
    selectedAutomation?.matchCriteria['RECORD']?.['requirements'] ?? [],
  )
  const [filterErrorMessage, setFilterErrorMessage] = useState<string>('')
  const [forceUpdateUuid, setForceUpdateUuid] = useState<string>(uuidv4())

  const baserowTableNotSelected = !selectedAutomationBaserowData?.table?.id

  const handleViewFilterChange = (
    _event: React.MouseEvent<HTMLElement>,
    filterView: AutomationFilterType,
  ) => {
    filterView !== null && setFilterType(filterView)
  }

  const [getTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      setBaserowTableFields(data.getBaserowTableFields as BaserowField[])
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [getTableViews, { loading: loadingTableViews }] = useLazyQuery<
    GetBaserowTableViews,
    GetBaserowTableViewsVariables
  >(GET_BASEROW_VIEWS, {
    onCompleted: (data) => {
      setAvailableViews(
        data.getBaserowTableViews
          .map((view) => ({
            value: view.id,
            label: view.name,
            slug: view.slug,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
          ),
      )
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const [updateAutomation] = useMutation<
    UpdateAutomation,
    UpdateAutomationVariables
  >(UPDATE_AUTOMATION, {
    onCompleted: () => {
      setAutomationIsSaving(false)
      toast.success('Automation Updated')
    },
    onError: () => {
      setAutomationIsSaving(false)
      toast.error('There was a problem updating your Automation')
    },
    refetchQueries: ['FindAutomationDashboardQuery', 'GetAutomationDetails'],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  const handleSaveDetails = () => {
    setFilterErrorMessage('')
    setAutomationIsSaving(true)

    const matchCriteria: MatchCriteriaType = {
      FIELD: {
        ...selectedAutomation.matchCriteria['FIELD'],
      },
      RECORD: {
        ...selectedAutomation.matchCriteria['RECORD'],
        useViewsAsFilters: filterType === AutomationFilterType.VIEW,
        selectedView:
          filterType === AutomationFilterType.VIEW ? selectedView : null,
        requirements:
          filterType === AutomationFilterType.FIELD ? requirements : [],
      },
      advancedLogic: {
        ...selectedAutomation.matchCriteria['advancedLogic'],
      },
    }

    if (filterType === AutomationFilterType.FIELD) {
      const validation = validateRequirements(requirements)

      if (validation.success) {
        updateAutomation({
          variables: {
            id: selectedAutomation.id,
            inputData: {
              matchCriteria: JSON.parse(JSON.stringify(matchCriteria)),
            },
            baserowData: null,
          },
        })
      } else {
        setAutomationIsSaving(false)
        setFilterErrorMessage(
          'There are missing values in your filters. Please check them and try again.',
        )
      }
    }

    if (filterType === AutomationFilterType.VIEW) {
      if (selectedView === null) {
        setFormErrors({ ...formErrors, view: 'A view must be selected.' })
        setAutomationIsSaving(false)
      } else {
        updateAutomation({
          variables: {
            id: selectedAutomation.id,
            inputData: {
              matchCriteria: JSON.parse(JSON.stringify(matchCriteria)),
            },
            baserowData: null,
          },
        })
      }
    }
  }

  const addNewRequirementGroup = () => {
    const newRequirementGroup = {
      rule: 'AND',
      requirements: [],
      id: uuidv4(),
    }

    setRequirements([...requirements, newRequirementGroup])
  }

  function findAndRemoveRequirement(groups, requirementId) {
    for (const group of groups) {
      const index = group?.requirements?.findIndex(
        (req) => req.id === requirementId,
      )
      if (index > -1) {
        return group?.requirements?.splice(index, 1)[0]
      }
      if (group?.requirements) {
        const foundReq = findAndRemoveRequirement(
          group?.requirements,
          requirementId,
        )
        if (foundReq) return foundReq
      }
    }
    return null
  }

  function findGroupById(groups, groupId) {
    for (const group of groups) {
      if (group.id === groupId) return group
      if (group.requirements) {
        const foundGroup = findGroupById(group.requirements, groupId)
        if (foundGroup) return foundGroup
      }
    }
    return null
  }

  const handleUpdateRequirementDnD = (draggedItemId, destinationGroupId) => {
    const updatedRequirements = [...requirements]

    const draggedRequirement = findAndRemoveRequirement(
      updatedRequirements,
      draggedItemId,
    )

    const destinationGroup = findGroupById(
      updatedRequirements,
      destinationGroupId,
    )
    if (destinationGroup && draggedRequirement) {
      destinationGroup.requirements.push(draggedRequirement)
    }

    setRequirements(updatedRequirements)
  }

  const handleGroupUpdate = (index: number, updatedGroup) => {
    const updatedRequirements = [...requirements]

    if (updatedGroup === null) {
      updatedRequirements.splice(index, 1)
    } else {
      updatedGroup.requirements = updatedGroup.requirements.map((req) => ({
        ...req,
      }))
      updatedRequirements[index] = updatedGroup
    }

    setRequirements(updatedRequirements)
  }

  const handleRequirementUpdate = (index: number, updatedReq) => {
    const updatedRequirements = [...requirements]
    updatedRequirements[index] = updatedReq
    setRequirements(updatedRequirements)
  }

  useEffect(() => {
    // Reset filter validation state
    setFilterErrorMessage('')

    const fetchTableViews = async () => {
      await getTableViews({
        variables: {
          input: { tableId: String(selectedAutomationBaserowData?.table?.id) },
        },
      })
    }
    const fetchTableFields = async () => {
      await getTableFields({
        variables: {
          input: {
            tableId: String(selectedAutomationBaserowData?.table?.id),
            workspaceId: String(selectedAutomationBaserowData?.workspace?.id),
          },
        },
      })
    }

    if (filterType === AutomationFilterType.VIEW) {
      // Only fetch if exists
      if (selectedAutomationBaserowData?.table?.id) {
        fetchTableViews()
      }
    } else {
      // Only fetch if exists
      if (
        selectedAutomationBaserowData?.workspace?.id &&
        selectedAutomationBaserowData?.table?.id
      ) {
        fetchTableFields()
      }
    }
  }, [filterType])

  useEffect(() => {
    // Reset validation
    if (selectedView) {
      setFormErrors({ ...formErrors, view: null })
    }
  }, [selectedView])

  // THIS causes re-rendering out the wazoo - but also REQUIRED until we can rewrite this whole bit. I don't have time right now :(
  // But if anyone asks why the UI flashes ... its this.
  useEffect(() => {
    setForceUpdateUuid(uuidv4())

    const checkRequirements = validateRequirements(requirements)
    if (checkRequirements.success) {
      setRequirementsInvalid(false)
    } else {
      setRequirementsInvalid(true)
    }
  }, [requirements])

  return (
    <div className="flex flex-col grow h-full">
      <div className="w-full py-6">
        <div className="px-4">
          {baserowTableNotSelected && (
            <div className="flex gap-6 mb-4 items-start justify-between bg-amber-100 p-4 rounded text-amber-600 border border-amber-600">
              Filters cannot be set until a Trigger Table is selected.
            </div>
          )}
        </div>
        <div className="px-4">
          <p className="text-lg mb-2">Filter Type</p>
          <div className="flex gap-4 items-start justify-between w-full">
            <div>
              {filterType === AutomationFilterType.VIEW && (
                <p className="text-gray-400 text-sm font-light">
                  Process automation when trigger record enters a view.
                </p>
              )}
              {filterType === AutomationFilterType.FIELD && (
                <p className="text-gray-400 text-sm font-light">
                  Process automation when trigger record matches field
                  requirements.
                </p>
              )}
            </div>
            <ToggleButtonGroup
              color="primary"
              value={filterType}
              exclusive
              onChange={handleViewFilterChange}
            >
              <ToggleButton
                disableRipple
                className={`w-28 py-1 text-gray-300 gap-3 border-gray-400 ${
                  filterType === AutomationFilterType.FIELD &&
                  '!bg-indigo-100 !text-indigo-600 border-indigo-600'
                }`}
                value={AutomationFilterType.FIELD}
              >
                <CheckCircleIcon className="w-5 h-5" />
                <span className="pt-0.5">{AutomationFilterType.FIELD}</span>
              </ToggleButton>
              <ToggleButton
                disableRipple
                className={`w-28 py-1 text-gray-300 gap-3 border-gray-400 ml-0 ${
                  filterType === AutomationFilterType.VIEW &&
                  '!bg-indigo-100 !text-indigo-600 border-indigo-600 !border-l-indigo-600'
                }`}
                value={AutomationFilterType.VIEW}
              >
                <CheckCircleIcon className="w-5 h-5" />
                <span className="pt-0.5">{AutomationFilterType.VIEW}</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        <Divider className="my-6" />

        {filterType === AutomationFilterType.VIEW && (
          <>
            {loadingTableViews && (
              <div className="pt-20">
                <AutomationFetchLoading text="Fetching Table Views" />
              </div>
            )}
            {!loadingTableViews && (
              <>
                <div className="mt-6 px-4">
                  <p className="text-lg mb-0">Select View</p>
                </div>
                <div className="mt-2 px-4">
                  <FormInputRow label="View">
                    <FormControl
                      className="w-full"
                      disabled={availableViews?.length === 0}
                      error={formErrors.view ? true : false}
                    >
                      <InputLabel
                        htmlFor="select-table-view"
                        className="-mt-1.5 !text-gray-400 font-light text-sm"
                      >
                        {availableViews?.length === 0 && 'No views available'}
                      </InputLabel>
                      <Autocomplete
                        disabled={availableViews?.length === 0}
                        value={selectedView}
                        options={availableViews}
                        onChange={(
                          _event: React.ChangeEvent,
                          newValue: BaserowViewOption,
                        ) => {
                          setSelectedView(newValue)
                        }}
                        inputValue={viewInputValue}
                        onInputChange={(_event, newInputValue) => {
                          setViewInputValue(newInputValue)
                        }}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => {
                          return option.value === value.value
                        }}
                        id="select-table-view"
                        data-testid="select-table-view"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              availableViews?.length > 0 && 'Select a view'
                            }
                          />
                        )}
                        fullWidth
                        size="small"
                        disableClearable
                      />
                      <FormHelperText>{formErrors.view}</FormHelperText>
                    </FormControl>
                  </FormInputRow>
                </div>
              </>
            )}
          </>
        )}
        {filterType === AutomationFilterType.FIELD && (
          <>
            {loadingTableFields && (
              <div className="pt-20">
                <AutomationFetchLoading text="Fetching Table Fields" />
              </div>
            )}

            {!loadingTableFields && (
              <div className="mt-6 px-4">
                <>
                  <div className="flex mb-4 gap-4 items-center justify-between">
                    <div>
                      <p className="text-lg mb-0">Requirements</p>

                      {requirements?.length === 0 && (
                        <p className="text-gray-400 text-sm font-light">
                          None set.
                        </p>
                      )}
                    </div>

                    {requirements?.length === 0 && (
                      <Button
                        disabled={baserowTableNotSelected}
                        fullWidth={false}
                        variant="outlined"
                        className="min-w-[0] hover:bg-indigo-100 px-4"
                        onClick={addNewRequirementGroup}
                        startIcon={<PlusIcon className="w-5 h-5" />}
                      >
                        <span className="pt-0.5">Add Requirement</span>
                      </Button>
                    )}
                  </div>
                  {requirements?.length > 0 && (
                    <DndProvider backend={HTML5Backend}>
                      <div key={forceUpdateUuid}>
                        {requirements?.map((requirement, index: number) =>
                          'requirements' in requirement ? (
                            <RequirementGroupComponent
                              key={index}
                              availableFields={baserowTableFields}
                              group={requirement}
                              onUpdate={(updatedGroup) =>
                                handleGroupUpdate(index, updatedGroup)
                              }
                              handleUpdateRequirementDnD={
                                handleUpdateRequirementDnD
                              }
                              topLevel
                            />
                          ) : (
                            <RequirementComponent
                              availableFields={baserowTableFields}
                              key={index}
                              requirement={requirement}
                              onUpdate={(updatedReq) =>
                                handleRequirementUpdate(index, updatedReq)
                              }
                            />
                          ),
                        )}
                      </div>
                    </DndProvider>
                  )}
                </>
              </div>
            )}
          </>
        )}
      </div>
      {!loadingTableFields && !loadingTableViews && (
        <>
          <div className="flex gap-4 items-center justify-between sticky bottom-0 bg-white p-6 border-t border-gray-300">
            <Button
              fullWidth={false}
              onClick={handleSaveDetails}
              loading={automationIsSaving}
              disabled={requirementsInvalid || baserowTableNotSelected}
            >
              Save
            </Button>
            <p className="text-red-500 text-sm mr-20">{filterErrorMessage}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default AutomationEditFilterForm
