import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Chip, Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'
import {
  GetBaserowTableFields,
  GetBaserowTableFieldsVariables,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { AutomationLogType } from 'src/components/Automations/AutomationHistory/AutomationLogDetailDrawerCell/AutomationLogDetailDrawerCell'
import { getAppropriateDurationFormat } from 'src/components/Automations/AutomationLogSection'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import Accordion from '../Library/Accordion'

import { AutomationBaserowData } from './AutomationLayout'
import { GET_BASEROW_FIELDS } from './queries'
import { BaserowField, EnrichedActionType, TruncatedType } from './utils'

interface AutomationDetailDrawerProps {
  automationRecord: AutomationLogType
  selectedAutomationBaserowData: AutomationBaserowData
}

type CriteriaResults = {
  newData: TransformData
  oldData: TransformData
}

type TransformData = Record<string, any>

type fieldValueType = {
  truncatedStringValue: string
  type: string
}

type TransformedData = Record<string, TransformedDataItem>
type TransformedDataItem = {
  value: fieldValueType
  result?: string
  type?: string
}

type mismatchedDataType = {
  fieldName: string
  oldValue: string
  newValue: string
  oldSuccess: boolean
  newSuccess: boolean
}

const DetailTitle: FC<{
  children: React.ReactNode
  alignRight?: boolean
  setWidth?: boolean
}> = ({ children, alignRight = false, setWidth = false }) => {
  return (
    <p
      className={`text-xs text-gray-500 pb-2 font-light ${
        alignRight && 'text-right'
      }  ${setWidth && 'min-w-[200px]'}`}
    >
      {children}
    </p>
  )
}

const DetailValue: FC<{
  children: React.ReactNode
  alignRight?: boolean
  setWidth?: boolean
}> = ({ children, alignRight = false, setWidth = false }) => {
  return (
    <p
      className={`text-sm pb-1 ${alignRight && 'text-right'} ${
        setWidth && 'min-w-[200px]'
      }`}
    >
      {children}
    </p>
  )
}

const AutomationLogDetailDrawer: FC<AutomationDetailDrawerProps> = ({
  automationRecord,
  selectedAutomationBaserowData,
}) => {
  // Baserow Fields for Automation Table
  const [baserowTableFields, setBaserowTableFields] = useState<BaserowField[]>(
    [],
  )

  const [getTableFields, { loading: loadingTableFields }] = useLazyQuery<
    GetBaserowTableFields,
    GetBaserowTableFieldsVariables
  >(GET_BASEROW_FIELDS, {
    onCompleted: (data) => {
      setBaserowTableFields(data.getBaserowTableFields as BaserowField[])
    },
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const getTableFieldName = (key: string): string => {
    const fieldNameTranslator = (
      automationRecord.automation?.action as unknown as EnrichedActionType[]
    ).reduce((acc, actionItem) => {
      return actionItem?.data?.reduce((innerAcc, dataItem) => {
        innerAcc['field_' + dataItem.fieldId] = dataItem.fieldName
        return innerAcc
      }, acc)
    }, {})
    if (key in fieldNameTranslator) {
      return fieldNameTranslator[key]
    }

    if (key.startsWith('field_')) {
      const fieldId = key.split('field_')[1]

      const matchingField = baserowTableFields.find(
        (field) => field.id.toString() === fieldId.toString(),
      )

      return matchingField?.name ?? 'Unknown Field'
    } else {
      return key
    }
  }

  const triggerData = automationRecord.triggerData as Record<string, any>

  const transformTransformedData = (data: TransformData): TransformedData => {
    const transformedData: TransformedData = {}

    // Get the baserow field name based on the ID saved

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const transformDataItem: fieldValueType = data[key]

        const fieldName = getTableFieldName(key)

        transformedData[fieldName] = {
          value: transformDataItem,
        }
      }
    }

    return transformedData
  }

  const { newData, oldData } =
    automationRecord.criteriaResults as CriteriaResults

  const [transformedTriggerData, setTransformedTriggerData] =
    useState<TransformedData>({})

  const eventTypeTranslator = (eventType: string): string => {
    switch (eventType) {
      case 'rows.created':
        return 'Row Created'
      case 'rows.updated':
        return 'Row Updated'
      case 'rows.deleted':
        return 'Row Deleted'
      default:
        return eventType
    }
  }

  const renderNestedResults = (
    data: Record<string, any>,
    title: string,
    level: number = 0,
  ): JSX.Element => {
    if (!data || !data.results) return null

    return (
      <Accordion
        removeDetailsPadding
        title={<p className="font-light">{title}</p>}
        customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
        borderstyle="!border-0 before:hidden !rounded-0 !overflow-hidden"
      >
        <div className="bg-gray-50 p-4 border-b">
          <Stack direction={'row'} spacing={2} className="mb-2 pb-1 border-b">
            <DetailValue setWidth>
              <b>Field</b>
            </DetailValue>
            <DetailValue setWidth>
              <b>Value</b>
            </DetailValue>
            <DetailValue setWidth>
              <b>Match</b>
            </DetailValue>
          </Stack>

          {data.results.map((item: Record<string, any>, index: number) => (
            <Stack key={index} direction={'row'} spacing={2}>
              {item.fieldName ? (
                <>
                  <DetailValue setWidth>
                    {`${
                      item.fieldName?.name === 'None' &&
                      item.fieldValue === 'None'
                        ? 'New Record'
                        : `${item?.fieldName?.name ?? item?.fieldName ?? ''}`
                    }`}
                  </DetailValue>
                  <DetailValue setWidth>
                    {`${item.fieldValue || '-'}`}
                  </DetailValue>

                  <DetailValue setWidth>
                    <div className="flex gap-2 items-center">
                      {item.success ? (
                        <CheckCircleIcon
                          className={'w-4 h-4 rounded-lg text-green-400'}
                        />
                      ) : (
                        <XCircleIcon
                          className={'w-4 h-4 rounded-lg text-amber-400'}
                        />
                      )}

                      {`${item.success ? 'Match' : 'Mismatch'}`}
                    </div>
                  </DetailValue>
                </>
              ) : item.results ? (
                renderNestedResults(item, `Level ${level + 1}`, level + 1)
              ) : null}
            </Stack>
          ))}
        </div>
      </Accordion>
    )
  }

  const renderMismatchedNestedResults = (
    oldData: TransformData,
    newData: TransformData,
  ): JSX.Element => {
    const mismatchedResults = findMismatchedResults(oldData, newData)
    if (mismatchedResults.length === 0) {
      return null
    }

    return (
      <Accordion
        removeDetailsPadding
        title={
          <p className="font-light">{`Field${
            mismatchedResults.length > 1 ? 's' : ''
          } That Triggered Automation`}</p>
        }
        customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
        borderstyle="!border-0 before:hidden !rounded-0 !overflow-hidden"
      >
        <div className="bg-gray-50 p-4">
          <Stack direction={'row'} spacing={2} className="mb-2 pb-1 border-b">
            <DetailValue setWidth>
              <b>Field</b>
            </DetailValue>
            <DetailValue setWidth>
              <b>Old Value</b>
            </DetailValue>
            <DetailValue setWidth>
              <b>New Value</b>
            </DetailValue>
          </Stack>

          {mismatchedResults.map((result: mismatchedDataType) => {
            // Conditionally render different content based on the condition
            return result.fieldName === 'None' && result.oldValue === 'None' ? (
              <Stack direction={'row'} spacing={2}>
                <DetailValue setWidth>No Field - New Record</DetailValue>
                <DetailValue setWidth>-</DetailValue>
                <DetailValue setWidth>-</DetailValue>
              </Stack>
            ) : (
              <Stack direction={'row'} spacing={2}>
                <DetailValue setWidth>{result?.fieldName ?? '-'}</DetailValue>
                <DetailValue setWidth>{result?.oldValue || '-'}</DetailValue>
                <DetailValue setWidth>{result?.newValue || '-'}</DetailValue>
              </Stack>
            )
          })}
        </div>
      </Accordion>
    )
  }

  const findMismatchedResults = (
    oldData: TransformData,
    newData: TransformData,
  ): mismatchedDataType[] => {
    const mismatches = []

    const compareNestedResults = (
      oldResults: Record<string, any>[],
      newResults: Record<string, any>[],
    ) => {
      if (!oldResults || !newResults) return

      for (let index = 0; index < oldResults.length; index++) {
        const oldResult: Record<string, any> = oldResults[index]
        const newResult: Record<string, any> = newResults[index]

        if (
          oldResult?.fieldName &&
          newResult?.fieldName &&
          oldResult?.success !== newResult?.success
        ) {
          mismatches.push({
            fieldName:
              oldResult?.fieldName?.name ?? oldResult?.fieldName ?? '-',
            oldValue: oldResult?.fieldValue,
            newValue: newResult?.fieldValue,
            oldSuccess: oldResult?.success,
            newSuccess: newResult?.success,
          })
        }

        if (oldResult?.results && newResult?.results) {
          compareNestedResults(oldResult?.results, newResult?.results)
        }
      }
    }

    compareNestedResults(oldData.results, newData.results)
    return mismatches
  }

  useEffect(() => {
    const fetchTableFields = async () => {
      await getTableFields({
        variables: {
          input: {
            tableId: String(selectedAutomationBaserowData?.table?.id),
            workspaceId: String(selectedAutomationBaserowData?.workspace?.id),
          },
        },
      })
    }

    fetchTableFields()
  }, [automationRecord])

  useEffect(() => {
    setTransformedTriggerData(
      transformTransformedData(triggerData.originalData),
    )
  }, [baserowTableFields])

  return (
    <Box className={'p-0 h-full'}>
      {loadingTableFields && (
        <div className="h-full py-32 grid place-items-center">
          <LoadingSpinner />
        </div>
      )}
      {!loadingTableFields && (
        <>
          {/* Run Overview */}
          <Stack spacing={3} className="p-6 border-b">
            <div className="flex gap-4 justify-between">
              <div>
                <p className="text-sm text-gray-500 pb-2 font-light">
                  {automationRecord.completedAt
                    ? dayjs(automationRecord.completedAt).format(
                        'DD/MM/YYYY [at] h:mm:ss A',
                      )
                    : dayjs(automationRecord.createdAt).format(
                        'DD/MM/YYYY [at] h:mm:ss A',
                      )}
                </p>
                <p className="text-lg flex gap-2 items-center">
                  {automationRecord.automation.name}
                </p>
              </div>

              <div>
                <DetailTitle alignRight>Path Called</DetailTitle>
                <DetailValue alignRight>{automationRecord.path}</DetailValue>
              </div>
            </div>

            <div className="flex flex-wrap gap-4 w-full justify-between">
              <div className="flex flex-wrap gap-6">
                <Stack>
                  <DetailTitle>Process Status</DetailTitle>
                  <DetailValue>
                    {automationRecord.status === 'COMPLETED' && (
                      <Chip
                        size="small"
                        className="rounded text-sm bg-emerald-100 text-emerald-500 px-2"
                        label="COMPLETED"
                      />
                    )}
                    {automationRecord.status === 'RUNNING' && (
                      <Chip
                        size="small"
                        className="rounded text-sm bg-blue-100 text-blue-500 px-2"
                        label="RUNNING"
                      />
                    )}
                    {automationRecord.status === 'FAILED' && (
                      <Chip
                        size="small"
                        className="rounded text-sm bg-red-100 text-red-500 px-2"
                        label="FAILED"
                      />
                    )}
                  </DetailValue>
                </Stack>
                <Stack>
                  <DetailTitle>Run Time</DetailTitle>
                  <DetailValue>
                    {getAppropriateDurationFormat(
                      automationRecord.createdAt,
                      automationRecord.completedAt,
                    )}
                  </DetailValue>
                </Stack>
                <Stack>
                  <DetailTitle>Event Type</DetailTitle>
                  <DetailValue>
                    {eventTypeTranslator(automationRecord.eventType)}
                  </DetailValue>
                </Stack>
              </div>
              <Stack>
                <DetailTitle alignRight>Event Id</DetailTitle>
                <DetailValue alignRight>{automationRecord.eventId}</DetailValue>
              </Stack>

              <div className="w-full mt-4">
                <Accordion
                  removeDetailsPadding
                  title={<p className="font-light">Original Record Data</p>}
                  customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
                  borderstyle="!border before:hidden !rounded-0 !overflow-hidden"
                >
                  <div className="bg-gray-50 p-6">
                    <Stack
                      direction={'row'}
                      spacing={2}
                      className="mb-2 pb-1 border-b"
                    >
                      <DetailValue setWidth>
                        <b>Field</b>
                      </DetailValue>
                      <DetailValue setWidth>
                        <b>Value</b>
                      </DetailValue>
                    </Stack>
                    {transformedTriggerData &&
                      Object.entries(transformedTriggerData).map(
                        ([key, value], id) => (
                          <Stack key={id} direction={'row'} spacing={2}>
                            <DetailValue setWidth>{key}</DetailValue>
                            <DetailValue setWidth>
                              {value.value.truncatedStringValue || '-'}
                            </DetailValue>
                          </Stack>
                        ),
                      )}
                  </div>
                </Accordion>
              </div>
            </div>
          </Stack>

          {/* Run Trigger */}
          <Stack className="p-6 border-b">
            <p className="mb-4">Trigger Step</p>
            <div className="flex flex-wrap gap-4 w-full justify-between">
              <div className="flex flex-wrap gap-6">
                <Stack>
                  <DetailTitle>Process Status</DetailTitle>
                  <DetailValue>
                    {/* The trigger success is always true as the log is only created if the automation is to run (ie., the triggers pass*/}
                    <Chip
                      size="small"
                      className="rounded text-sm bg-emerald-100 text-emerald-500 px-2"
                      label="Success"
                    />
                  </DetailValue>
                </Stack>
                <Stack>
                  <DetailTitle>Trigger Row Id</DetailTitle>
                  <DetailValue>{triggerData.triggerRowId}</DetailValue>
                </Stack>
                <Stack>
                  <DetailTitle>Trigger Table</DetailTitle>
                  <DetailValue>{`${triggerData.triggerTableName} (#${triggerData.triggerTableId})`}</DetailValue>
                </Stack>
                <Stack>
                  <DetailTitle>Trigger Base Id</DetailTitle>
                  <DetailValue>{triggerData.triggerBaseId}</DetailValue>
                </Stack>
                {triggerData?.triggerUsesViewData && (
                  <Stack>
                    <DetailTitle>Trigger View</DetailTitle>
                    <DetailValue>{`${triggerData?.triggerViewName} (#${triggerData?.triggerViewId})`}</DetailValue>
                  </Stack>
                )}
              </div>

              <div className="w-full mt-4">
                <Accordion
                  removeDetailsPadding
                  title={<p className="font-light">Trigger Details</p>}
                  customstyle="before:hidden !border-0 bg-white py-0 !overflow-hidden !rounded-0"
                  borderstyle="!border before:hidden !rounded-0 !overflow-hidden"
                >
                  <div className="bg-gray-50">
                    {renderNestedResults(oldData, 'Original Data')}
                    {renderNestedResults(newData, 'Updated Data')}
                    {renderMismatchedNestedResults(oldData, newData)}
                  </div>
                </Accordion>
              </div>
            </div>
          </Stack>

          {/* Run Action */}
          <Stack className="p-6 border-b">
            <p className="mb-4">Action Step</p>
            <div className="flex flex-col gap-4 w-full justify-between">
              {!automationRecord.actionData && (
                <Chip
                  size="small"
                  className={`capitalize rounded text-sm px-2 ${automationRecord.status === 'FAILED' ? 'bg-red-100 text-red-500' : 'bg-blue-100 text-blue-500'} `}
                  label={automationRecord.status}
                />
              )}
              {(
                automationRecord.actionData as Record<
                  string,
                  TruncatedType | string
                >[]
              )?.map(
                (
                  actionData: Record<string, TruncatedType | string>,
                  index: number,
                ) => (
                  <>
                    <Stack>
                      <DetailValue>
                        <b>Action No. {index + 1}</b>
                      </DetailValue>
                    </Stack>
                    <div className="flex flex-wrap gap-6" key={index}>
                      <Stack>
                        <DetailTitle>Process Status</DetailTitle>
                        <DetailValue>
                          {actionData.actionSuccess ? (
                            <Chip
                              size="small"
                              className="rounded text-sm bg-emerald-100 text-emerald-500 px-2"
                              label="Success"
                            />
                          ) : (
                            <Chip
                              size="small"
                              className="rounded text-sm bg-red-100 text-red-500 px-2"
                              label="Fail"
                            />
                          )}
                        </DetailValue>
                      </Stack>
                      <Stack>
                        <DetailTitle>Actioned Row Id</DetailTitle>
                        <DetailValue>{triggerData.triggerRowId}</DetailValue>
                      </Stack>
                      <Stack>
                        <DetailTitle>Actioned Table</DetailTitle>
                        <DetailValue>{`${triggerData.triggerTableName} (#${triggerData.triggerTableId})`}</DetailValue>
                      </Stack>
                      <Stack>
                        <DetailTitle>Actioned Base Id</DetailTitle>
                        <DetailValue>{triggerData.triggerBaseId}</DetailValue>
                      </Stack>

                      <div className="bg-gray-50 p-4 w-full border rounded">
                        <Stack
                          direction={'row'}
                          spacing={2}
                          className="mb-2 pb-1 border-b"
                        >
                          <DetailValue setWidth>
                            <b>
                              {actionData?.actionType === 'SEND_NOTIFICATION'
                                ? 'Recipient'
                                : 'Field'}
                            </b>
                          </DetailValue>
                          <DetailValue setWidth>
                            <b>
                              {actionData?.actionType === 'SEND_NOTIFICATION'
                                ? 'Details'
                                : 'New Value'}
                            </b>
                          </DetailValue>
                        </Stack>

                        {actionData &&
                          Object.entries(actionData.actionData).map(
                            ([key, value]: [any, any], id) => (
                              <Stack key={id} direction={'row'} spacing={2}>
                                <DetailValue setWidth>
                                  {getTableFieldName(key)}
                                </DetailValue>
                                <DetailValue setWidth>
                                  {value.truncatedStringValue || '-'}
                                </DetailValue>
                              </Stack>
                            ),
                          )}
                      </div>
                    </div>
                  </>
                ),
              )}
            </div>
          </Stack>
        </>
      )}
    </Box>
  )
}

export default AutomationLogDetailDrawer
