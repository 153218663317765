import { FC, MouseEvent, useState } from 'react'

import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { IconButton, Menu, MenuItem } from '@mui/material'

interface FolderTitleActionBarProps {
  id: number
  name: string
  onClick: (id: number) => void
  onArchive: (id: number) => void
}

const FolderTitleActionBar: FC<FolderTitleActionBarProps> = ({
  id,
  name,
  onClick,
  onArchive,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const stopEvents = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Set the anchor
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    // Unset anchor
    setAnchorEl(null)
  }

  const handleSettings = () => {
    // Close the menu - modal popped up
    handleClose()

    // Set as editing category
    onClick(id)
  }

  const handleArchive = () => {
    // Close the menu - confirm popped up
    handleClose()

    // Archive it
    onArchive(id)
  }

  return (
    <div className="flex items-center gap-1 text-gray-500">
      <IconButton
        disableRipple
        className="p-0.5 hover:!bg-gray-300"
        onClick={(event) => {
          stopEvents(event)
          handleClick(event)
        }}
      >
        <Cog6ToothIcon
          className="h-6 w-6"
          data-testid={`automation-category-settings-${name}`}
        />
      </IconButton>
      <Menu
        elevation={5}
        id={'automations-settings-' + id}
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          stopEvents(event)
          handleClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          borderRadius: '20px',
          '& .MuiMenu-list': {
            padding: '0',
          },
          '& .MuiMenuItem-root ': {
            padding: '10px 15px',
          },
        }}
      >
        <MenuItem
          disableRipple
          onClick={(event) => {
            stopEvents(event)
            handleSettings()
          }}
        >
          Edit Category
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={(event) => {
            stopEvents(event)
            handleArchive()
          }}
          className="text-red-500 hover:bg-red-50"
          data-testid="delete-category"
        >
          Delete Category
        </MenuItem>
      </Menu>
    </div>
  )
}

export default FolderTitleActionBar
