import { FC, MouseEvent, useState } from 'react'

import {
  EllipsisVerticalIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  DocumentCheckIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline'
import { Alert, Menu, MenuItem } from '@mui/material'

import Button from '../Library/Button'
import IconButton from '../Library/IconButton'

interface AutomationToolbarProps {
  automationId: number
  automationName: string
  automationCategoryId: number
  onDelete: (id: number) => void
  handleOpenDetails: () => void
  handleOpenHistory: () => void
  handleDuplicateAutomation: () => void
}

const AutomationToolbar: FC<AutomationToolbarProps> = ({
  automationId,
  automationName = '',
  automationCategoryId,
  onDelete,
  handleOpenDetails,
  handleOpenHistory,
  handleDuplicateAutomation,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const stopEvents = (event) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Set the anchor
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    // Unset anchor
    setAnchorEl(null)
  }

  const openDetails = () => {
    // Close the menu
    handleClose()

    // Show details component
    handleOpenDetails()
  }

  const openHistory = () => {
    // Close the menu
    handleClose()

    // Show history component
    handleOpenHistory()
  }

  const handleDuplicate = () => {
    // Close the menu
    handleClose()

    // Show details component
    handleDuplicateAutomation()
  }

  const handleDelete = () => {
    // Close the menu - confirm popped up
    handleClose()

    // Delete it
    onDelete(automationId)
  }

  return (
    <div className="flex items-center justify-between gap-4 w-full grow ">
      <p className="text-lg line-clamp-1">{automationName}</p>
      <div className="flex items-center gap-4">
        {automationCategoryId === null && (
          <Alert
            className="items-center rounded"
            severity="error"
            icon={false}
            action={
              <Button
                variant="outlined"
                className="py-1 pb-0.5 px-2 border !border-red-800 text-red-800 hover:bg-red-100"
                size="small"
                onClick={openDetails}
              >
                Update
              </Button>
            }
          >
            <p className="pt-0.5">
              Your automation needs to be in a category to continue editing.
            </p>
          </Alert>
        )}
        <IconButton
          disableRipple
          className="hover:!bg-gray-300 grow w-12 h-12"
          onClick={(event) => {
            stopEvents(event)
            handleClick(event)
          }}
        >
          <EllipsisVerticalIcon className="h-6 w-6" />
        </IconButton>
        <Menu
          elevation={5}
          id={'automation-actions'}
          anchorEl={anchorEl}
          open={open}
          onClose={(event) => {
            stopEvents(event)
            handleClose()
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            borderRadius: '20px',
            '& .MuiMenu-list': {
              padding: '0',
            },
            '& .MuiMenuItem-root ': {
              padding: '10px 15px',
            },
          }}
        >
          <MenuItem
            disableRipple
            onClick={(event) => {
              stopEvents(event)
              openDetails()
            }}
          >
            <span className="flex items-start gap-2">
              <DocumentCheckIcon className="h-5 w-5" />
              Automation Details
            </span>
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={(event) => {
              stopEvents(event)
              openHistory()
            }}
          >
            <span className="flex items-start gap-2">
              <QueueListIcon className="h-5 w-5" />
              Run History
            </span>
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={(event) => {
              stopEvents(event)
              handleDuplicate()
            }}
          >
            <span className="flex items-start gap-2">
              <DocumentDuplicateIcon className="h-5 w-5" />
              Duplicate Automation
            </span>
          </MenuItem>
          <MenuItem
            disableRipple
            onClick={(event) => {
              stopEvents(event)
              handleDelete()
            }}
            className="text-red-500 hover:bg-red-50"
          >
            <span className="flex items-start gap-2">
              <TrashIcon className="h-5 w-5" />
              Delete Automation
            </span>
          </MenuItem>
        </Menu>
      </div>
    </div>
  )
}

export default AutomationToolbar
