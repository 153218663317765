import React, { useState } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material'

import { AdvancedLogicOptions } from 'src/components/Automations/AutomationEditForms/utils'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import { SelectOption } from '../AutomationRequirements/AutocompleteComponents'

const LogicTypeSelector = () => {
  // The advanced logic options. Currently, it's only Find Related Records. This will need to be lifted up when more than one option is available
  const [advancedLogicOptions, _setAdvancedLogicOptions] = useState<
    SelectOption[]
  >([
    {
      value: AdvancedLogicOptions.FIND_RELATED_RECORDS,
      label: AdvancedLogicOptions.FIND_RELATED_RECORDS,
    },
  ])

  // Advanced Logic type that is currently Find Only
  const [selectedLogicOption, setSelectedLogicOption] = useState<SelectOption>({
    value: AdvancedLogicOptions.FIND_RELATED_RECORDS,
    label: AdvancedLogicOptions.FIND_RELATED_RECORDS,
  })

  return (
    <FormInputRow label="Logic Type">
      <FormControl
        className="w-full"
        disabled={advancedLogicOptions?.length === 0}
        error={!selectedLogicOption}
      >
        <InputLabel
          htmlFor="select-create-Logic-Type"
          className="-mt-1.5 !text-gray-400 font-light text-sm"
        >
          {advancedLogicOptions?.length === 0 && 'No Logic Types available'}
        </InputLabel>
        <Autocomplete
          disabled={true} // Disabled because we have no other options at the moment
          value={selectedLogicOption}
          options={advancedLogicOptions}
          onChange={(_event: React.ChangeEvent, newValue: SelectOption) => {
            setSelectedLogicOption(newValue)
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value
          }}
          id="select-create-Logic-Type"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                advancedLogicOptions?.length > 0 && 'Select a Logic Type'
              }
              error={!selectedLogicOption}
            />
          )}
          fullWidth
          size="small"
          disableClearable
        />
        <FormHelperText>
          {!selectedLogicOption && 'Select a Logic Type'}
        </FormHelperText>
      </FormControl>
    </FormInputRow>
  )
}

export default LogicTypeSelector
