import { FC } from 'react'

import {
  CellAutomationCategory,
  CellAutomations,
} from 'src/components/Automations/AutomationCell/AutomationCell'

import AutomationLayout from './AutomationLayout'

export enum SortByOption {
  NAME = 'Name',
  CREATED = 'Created Date',
  MODIFIED = 'Modified Date',
  RUN = 'Last Run',
}

interface AutomationMainSectionProps {
  refetch: () => Promise<unknown>
  uncategorisedAutomations: CellAutomations
  automationCategories: CellAutomationCategory[]
}

const AutomationMainSection: FC<AutomationMainSectionProps> = ({
  uncategorisedAutomations,
  automationCategories,
}) => {
  // If we have uncategorised Items, add another category
  const uncategorisedCategory: CellAutomationCategory[] =
    uncategorisedAutomations?.length > 0
      ? [
          {
            id: 0,
            name: 'Uncategorised',
            rank: 0,
            parentCategoryId: null,
            automations: uncategorisedAutomations,
            childCategories: [],
          },
        ]
      : []

  return (
    <AutomationLayout
      automationCategories={automationCategories}
      uncategorisedAutomations={uncategorisedCategory}
    />
  )
}

export default AutomationMainSection
