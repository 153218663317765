import React, { FC } from 'react'

import dayjs from 'dayjs'

import AutomationTrackConditional from './AutomationTrackConditional'
import { ValueChip, ValueChipTheme } from './AutomationTrackValueChip'

interface AutomationTrackFilterProps {
  filterRequirements?: any[] // comes from saved JSON object. Could be anything
}

interface RequirementProps {
  requirement: any
  children: React.ReactNode
  isChild?: boolean
}

export const getCleanedValue = (
  value,
  fieldType = '',
  chipTheme: ValueChipTheme = ValueChipTheme.VIOLET,
): React.ReactNode => {
  if (!value) return

  // Check if number (numbers can influence the date validity)
  if (typeof value === 'number') {
    return (
      <ValueChip key={String(value)} value={String(value)} theme={chipTheme} />
    )
  }

  // Check if array
  if (Array.isArray(value)) {
    const items = value.map((item, index) => (
      <ValueChip key={index} value={item} theme={chipTheme} />
    ))
    return <span className="inline-block py-2">{items}</span>
  }

  // Check if date
  if (fieldType === 'date' && dayjs(value).isValid()) {
    return (
      <ValueChip
        key={String(value)}
        value={dayjs(value).format('DD/MM/YYYY')}
        theme={chipTheme}
      />
    )
  }

  return (
    <ValueChip key={String(value)} value={String(value)} theme={chipTheme} />
  )
}

// Recursive Component
const Requirement: FC<RequirementProps> = ({
  requirement,
  children,
  isChild = false,
}) => {
  const isRequirementGroup = requirement?.requirements?.length > 0

  const requirementParams = Object.keys(requirement?.filter?.params ?? {})

  return (
    <>
      <div
        className={`text-md relative w-full rounded border border-gray-300 bg-white p-4 ${isChild && '!bg-white'} ${isRequirementGroup ? '!bg-gray-50' : 'px-3'} `}
      >
        {isRequirementGroup && (
          <p className="pb-4 text-xs uppercase text-gray-400">
            Grouped Condition
          </p>
        )}
        {!isRequirementGroup && (
          <p className="items-center gap-2 text-gray-600">
            <span className="mr-2 font-light">Field</span>
            <ValueChip
              theme={ValueChipTheme.BLUE}
              value={requirement?.fieldName}
            />
            <span className="grow pr-2 font-light lowercase">
              {requirement?.filter?.label}
            </span>
            <span className="items-center gap-2 text-gray-600">
              {requirementParams.map((param, index) => {
                return (
                  <span key={index} className="">
                    {getCleanedValue(
                      requirement?.filter?.params[param]?.valueLabel ||
                        requirement?.filter?.params[param]?.value,
                    )}
                  </span>
                )
              })}
            </span>
          </p>
        )}
        {requirement?.requirements?.map((subRequirement, index) => {
          return (
            <Requirement key={index} requirement={subRequirement} isChild>
              <>
                {index + 1 !== requirement?.requirements?.length && (
                  <AutomationTrackConditional text={requirement?.rule} />
                )}
              </>
            </Requirement>
          )
        })}
      </div>
      {children}
    </>
  )
}

const AutomationTrackFilter: FC<AutomationTrackFilterProps> = ({
  filterRequirements = [],
}) => {
  return (
    <div>
      {filterRequirements.map((topLevelRequirement, index) => {
        return (
          <div key={index}>
            {topLevelRequirement?.requirements?.map(
              (requirement, requirementIndex) => {
                return (
                  <Requirement key={requirementIndex} requirement={requirement}>
                    <>
                      {requirementIndex + 1 !==
                        topLevelRequirement?.requirements?.length && (
                        <AutomationTrackConditional
                          text={topLevelRequirement?.rule}
                        />
                      )}
                    </>
                  </Requirement>
                )
              },
            )}
          </div>
        )
      })}
    </div>
  )
}

export default AutomationTrackFilter
