import { FC } from 'react'

import AutomationTrackActionItemBaserow from 'src/components/Automations/AutomationTrack/AutomationTrackActionItemBaserow'
import AutomationTrackActionItemNotification from 'src/components/Automations/AutomationTrack/AutomationTrackActionNotifications'

import {
  ActionExecutionType,
  AdvancedLogicObject,
  EnrichedActionType,
} from '../utils'

interface ActionItem {
  fieldName: string
  fieldType: string
  fieldId: string
  inputType: {
    label: string
    value: string
    params: {
      [x: number]: {
        type: string
        label: string
        value: number | string
        valueLabel: string
        dataType: string
        isConditional: boolean
        options?: string[]
        dependency?: {
          reference: number
          conditions: {
            [optionValue: string]: {
              dataType: string
            }
          }
        }
      }
    }
  }
}

interface AutomationTrackActionProps {
  action: EnrichedActionType
  actionIndex: number
  advancedLogic: AdvancedLogicObject
  onActionClick: () => void
}

const AutomationTrackAction: FC<AutomationTrackActionProps> = ({
  action,
  actionIndex,
  advancedLogic,
  onActionClick = () => {},
}) => {
  const useAdvancedLogic = advancedLogic?.useAdvancedLogic ?? false

  const actionType = action.action as ActionExecutionType

  return (
    <div
      className="relative w-full rounded border border-gray-300 bg-gray-50 p-4 px-3"
      role="button"
      onClick={onActionClick}
      onKeyDown={() => {}}
      tabIndex={0}
    >
      <p className="capitalize">
        Action {actionIndex}:&nbsp;
        <span className="capitalize text-gray-500">
          {useAdvancedLogic &&
            `Update record in table ${advancedLogic['FIND']?.linkedFieldData?.linkedToTable} that match ${advancedLogic['FIND']?.linkedFieldData?.label}`}
          {!useAdvancedLogic &&
            ` ${action.action.split('_').join(' ').toLowerCase()} Event `}
        </span>
      </p>
      <div className="mt-3 flex flex-col gap-3">
        {[
          ActionExecutionType.UPDATE,
          ActionExecutionType.CREATE,
          ActionExecutionType.UPDATE_FROM_ADVANCED_LOGIC,
        ].includes(actionType) &&
          action.data.map((action, index) => {
            return (
              <AutomationTrackActionItemBaserow key={index} action={action} />
            )
          })}

        {[
          ActionExecutionType.SEND_NOTIFICATION,
          ActionExecutionType.SEND_EMAIL,
        ].includes(actionType) &&
          action.notifyUserData.map((action, index) => {
            return (
              <AutomationTrackActionItemNotification
                key={index}
                action={action}
                actionType={actionType}
              />
            )
          })}
      </div>
    </div>
  )
}

export default AutomationTrackAction
