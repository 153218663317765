import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { ToggleAutomation, ToggleAutomationVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Switch from 'src/components/Library/Switch/Switch'
import { useConfirm } from 'src/lib/hooks/Confirmation'

import { CellAutomation } from '../AutomationCell'
import { TOGGLE_AUTOMATION } from '../queries'
import type { EnrichedActionType } from '../utils'

interface AutomationListItemProps {
  automation: CellAutomation
  selected?: boolean
  setSelectedAutomation: Dispatch<SetStateAction<CellAutomation>>
}

const AutomationListItem: FC<AutomationListItemProps> = ({
  automation,
  selected = false,
  setSelectedAutomation,
}) => {
  const [automationEnabled, setAutomationEnabled] = useState(automation.active)

  const [automationIsToggling, setAutomationIsToggling] =
    useState<boolean>(false)

  const confirmToggle = useConfirm()

  const [toggleAutomation] = useMutation<
    ToggleAutomation,
    ToggleAutomationVariables
  >(TOGGLE_AUTOMATION, {
    onCompleted: (data) => {
      toast.success(
        `Automation ${
          data.toggleAutomation.active ? 'Activated' : 'Deactivated'
        }`,
      )
      setAutomationIsToggling(false)
    },
    onError: () => {
      toast.error('There was a problem updating your Automation.')
      setAutomationIsToggling(false)
    },
    awaitRefetchQueries: true,
    refetchQueries: ['GetAutomationDetails'],
  })

  const handleAutomationToggle = (id, active, value) => {
    confirmToggle({
      title: `${value ? 'Activate' : 'Deactivate'} Automation`,
      description: `Are you sure you want to ${
        value ? 'activate' : 'deactivate'
      } this automation?`,
      confirmationText: value ? 'Activate' : 'Deactivate',
    }).then(() => {
      setAutomationEnabled(value)
      setAutomationIsToggling(true)
      toggleAutomation({
        variables: {
          id,
          input: {
            active,
          },
        },
      })
    })
  }

  const automationActions =
    automation?.action as unknown as EnrichedActionType[]

  const stepCount = automationActions?.length ?? 0
  const runCount = automation?.automationRunsThisMonth

  const lastRun = dayjs(automation?.lastRun)
  const lastRunDate = lastRun.isValid()
    ? lastRun.format('DD/MM/YYYY hh:mm:ss a')
    : '-'

  const automationIsValid = automation.isValidConfig.valid

  // Reset toggle when we refetch
  useEffect(() => {
    setAutomationEnabled(automation.active)
  }, [automation])

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => {
        setSelectedAutomation(automation)
      }}
      onKeyDown={(event) => {
        event.key === 'Enter' && setSelectedAutomation(automation)
      }}
      className={`cursor-pointer !border p-4 py-2 text-left ${
        selected
          ? '!border-indigo-600 bg-indigo-50 last-of-type:rounded-b-lg hover:bg-indigo-100'
          : '!border-gray-50 !border-b-gray-200 bg-gray-50 hover:bg-gray-100'
      }`}
    >
      <div className="flex items-start justify-between gap-2">
        <p className="mb-2 line-clamp-1 grow pt-2 text-lg">{automation.name}</p>
        <div className="flex items-center justify-between gap-2">
          {!automationIsValid && (
            <Tooltip
              title={
                <p className="text-xs font-light">
                  Your Automation has invalid configuration.
                </p>
              }
              arrow
            >
              <ExclamationCircleIcon className="h-6 w-6 text-red-400" />
            </Tooltip>
          )}
          <Switch
            color="primary"
            checked={automationEnabled}
            disabled={
              automationIsToggling ||
              automation?.automationCategoryId === null ||
              (!automationIsValid && !automationEnabled)
            }
            onChange={(event, value) => {
              event.stopPropagation()
              handleAutomationToggle(automation.id, !automationEnabled, value)
            }}
            name="toggleAutomationEnabled"
            data-intercom-target="publish-automation"
            data-testid={`toggle-automation-${automation.name}`}
          />
        </div>
      </div>
      <p className="mb-4 line-clamp-2 text-sm text-gray-500">
        {automation.description}
      </p>

      <div className="flex flex-wrap items-center justify-between gap-4 text-xs text-gray-400">
        <p>
          {/* {stepCount} Step{stepCount === 1 ? '' : 's'}, {runCount}
          {runCount >= AUTOMATION_LOG_LIMIT && '+'} Run
          {runCount === 1 ? '' : 's'} this Month */}
          {stepCount} Step{stepCount === 1 ? '' : 's'}
        </p>
        {/* <p>Last Run: {lastRunDate}</p> */}
      </div>
    </div>
  )
}

export default AutomationListItem
