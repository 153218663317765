import React, { FC, useEffect, useState } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from '@mui/material'
import { UpdateAutomation, UpdateAutomationVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button/Button'

import { AutomationCategoryOption } from '../AutomationModals/AutomationCategoryModal'
import { UPDATE_AUTOMATION } from '../queries'

import { AutomationQueryType } from './AutomationDetails'

export interface FormErrors {
  name: string
  category: string
}

interface EditAutomationDetailsFormProps {
  automation: AutomationQueryType
  automationCategoryOptions: AutomationCategoryOption[]
}

const EditAutomationDetailsForm: FC<EditAutomationDetailsFormProps> = ({
  automation,
  automationCategoryOptions,
}) => {
  // Form States
  const [automationIsSaving, setAutomationIsSaving] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<FormErrors>({
    name: null,
    category: null,
  })

  // Field Inputs
  const [newAutomationName, setNewAutomationName] = useState<string>(
    automation?.name ?? '',
  )
  const [newAutomationDescription, setNewAutomationDescription] =
    useState<string>(automation?.description ?? '')

  const defaultCategoryOption = automationCategoryOptions.find(
    (category) => category.id === automation.automationCategoryId,
  )

  const [categorySelectInput, setCategorySelectInput] =
    useState<AutomationCategoryOption>(defaultCategoryOption)

  const [updateAutomation] = useMutation<
    UpdateAutomation,
    UpdateAutomationVariables
  >(UPDATE_AUTOMATION, {
    onCompleted: () => {
      setAutomationIsSaving(false)
      toast.success('Automation Updated')
    },
    onError: () => {
      setAutomationIsSaving(false)
      toast.error('There was a problem updating your Automation')
    },
    refetchQueries: ['FindAutomationDashboardQuery', 'GetAutomationDetails'],
    awaitRefetchQueries: true,
  })

  const validateRequiredFields = (): boolean => {
    const errors: FormErrors = {
      name: !newAutomationName ? 'Automation name cannot be empty' : null,
      category: !categorySelectInput
        ? 'An Automation Category must be selected'
        : null,
    }

    const hasErrors = Object.values(errors).some((error) => error !== null)

    if (hasErrors) {
      setFormErrors(errors)
      return false
    } else {
      return true
    }
  }

  const handleSaveDetails = () => {
    setAutomationIsSaving(true)
    const validatedInputs = validateRequiredFields()

    if (validatedInputs) {
      updateAutomation({
        variables: {
          id: automation.id,
          inputData: {
            name: newAutomationName,
            description: newAutomationDescription,
            automationCategoryId: categorySelectInput.id,
          },
        },
      })
    } else {
      setAutomationIsSaving(false)
    }
  }

  useEffect(() => {
    // Revalidate field
    if (newAutomationName) {
      setFormErrors({ ...formErrors, name: null })
    }
  }, [newAutomationName])

  useEffect(() => {
    // Revalidate field
    if (categorySelectInput) {
      setFormErrors({ ...formErrors, category: null })
    }
  }, [categorySelectInput])

  useEffect(() => {
    setNewAutomationName(automation.name)
    setNewAutomationDescription(automation.description)
  }, [automation])

  return (
    <div className="w-full pt-4">
      <FormInputRow label="Name">
        <FormControl fullWidth error={formErrors.name ? true : false}>
          <TextField
            size="small"
            fullWidth
            placeholder="Automation Name"
            required
            error={formErrors?.name ? true : false}
            disabled={automationIsSaving}
            value={newAutomationName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewAutomationName(event.target.value)
            }}
          />
          <FormHelperText>{formErrors.name}</FormHelperText>
        </FormControl>
      </FormInputRow>

      <FormInputRow label="Description">
        <TextField
          size="small"
          fullWidth
          placeholder="Automation Description"
          disabled={automationIsSaving}
          value={newAutomationDescription}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewAutomationDescription(event.target.value)
          }}
        />
      </FormInputRow>

      <FormInputRow label="Category">
        <FormControl
          error={formErrors.category ? true : false}
          className="w-full"
          disabled={
            automationCategoryOptions?.length === 0 || automationIsSaving
          }
        >
          <Autocomplete
            disableClearable
            id="automation-category-change"
            size="small"
            fullWidth
            options={automationCategoryOptions}
            getOptionLabel={(option: AutomationCategoryOption) => option.label}
            renderOption={(props, option: AutomationCategoryOption) => {
              return (
                <li
                  {...props}
                  className={`p-2 px-4 flex gap-2 items-center hover:bg-gray-100 ${
                    option?.parentId ? 'pl-8 ' : ' bg-gray-50'
                  }`}
                >
                  {option.label}
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                error={formErrors?.category ? true : false}
                {...params}
                placeholder={
                  automationCategoryOptions?.length === 0
                    ? 'No options available'
                    : 'Select a category'
                }
              />
            )}
            value={categorySelectInput}
            onChange={(_, newValue: AutomationCategoryOption) => {
              setCategorySelectInput(newValue)
            }}
            isOptionEqualToValue={(
              option: AutomationCategoryOption,
              value: AutomationCategoryOption,
            ) => {
              return option.id === value?.id
            }}
          />
          <FormHelperText>{formErrors.category}</FormHelperText>
        </FormControl>
      </FormInputRow>

      <div className="flex items-center justify-end pt-4">
        <Button
          fullWidth={false}
          onClick={handleSaveDetails}
          loading={automationIsSaving}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

export default EditAutomationDetailsForm
