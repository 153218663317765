import { FC } from 'react'

import { UserIcon } from '@heroicons/react/24/outline'
import { IconoirProvider } from 'iconoir-react'

import { getIconForField } from 'src/components/HubDash/RecordExpand/RecordExpand'

import { selectColorConverter } from '../utils'

export interface SelectOption {
  value: string
  label: string
  color?: string
  type?: string
}

export const AutocompleteSelectedChip: FC<{
  option: SelectOption
  props: any
  index: number | string
}> = ({ option, props, index }) => {
  return (
    <div
      {...props({ index })}
      className="mb-1 mr-1 flex items-center justify-between gap-2 rounded border bg-gray-100 p-0.5 px-2"
      key={option.value}
    >
      {option?.color && (
        <div
          className="h-3 w-3 rounded"
          style={{
            backgroundColor: selectColorConverter(option.color),
          }}
        ></div>
      )}
      <span className="pt-0.5">{option.label}</span>
    </div>
  )
}

export const AutocompleteSelectedOption: FC<{
  option: SelectOption
  props: any
  avatar?: boolean
  useFieldIcons?: boolean
}> = ({ option, props, avatar = false, useFieldIcons }) => {
  return (
    <li {...props}>
      <div className="flex items-center justify-between gap-4">
        {option?.color && (
          <div
            className="h-5 w-5 rounded"
            style={{
              backgroundColor: selectColorConverter(option.color),
            }}
          ></div>
        )}
        {!useFieldIcons && avatar && <UserIcon className="h-4 w-4" />}
        {useFieldIcons && !avatar && (
          <IconoirProvider
            iconProps={{
              color: '#AAAAAA',
              strokeWidth: 2,
              width: '1em',
              height: '1em',
            }}
          >
            {getIconForField({ type: option?.type })}
          </IconoirProvider>
        )}

        <p>{option.label}</p>
      </div>
    </li>
  )
}
