import React, { FC } from 'react'

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'

import { ActionExecutionType } from 'src/components/Automations/utils'

interface ActionTypeOptionsProps {
  handleChangeAction: (
    event: React.MouseEvent<HTMLElement>,
    newActionType: ActionExecutionType,
  ) => void
  selectedActionType: ActionExecutionType
  useAdvancedLogic: boolean
}

type ActionOption = {
  label: string
  value: ActionExecutionType
  showOnAdvancedLogic: boolean
}

const actionOptions: ActionOption[] = [
  {
    label: 'Create Record',
    value: ActionExecutionType.CREATE,
    showOnAdvancedLogic: false,
  },
  {
    label: 'Update Record',
    value: ActionExecutionType.UPDATE,
    showOnAdvancedLogic: true,
  },
  {
    label: 'Notify Someone',
    value: ActionExecutionType.SEND_NOTIFICATION,
    showOnAdvancedLogic: false,
  },
  {
    label: 'Send Email',
    value: ActionExecutionType.SEND_EMAIL,
    showOnAdvancedLogic: false,
  },
]

const ActionTypeOptions: FC<ActionTypeOptionsProps> = ({
  selectedActionType,
  handleChangeAction,
  useAdvancedLogic,
}) => {
  return (
    <Stack spacing={2} className={'border-t'}>
      <p className="p-4">Action Type</p>
      <div className="!m-0 flex w-full items-center justify-between gap-4 border-gray-300 px-4 pb-4">
        <div className="w-full">
          <ToggleButtonGroup
            color="primary"
            value={selectedActionType}
            exclusive
            onChange={handleChangeAction}
            disabled={useAdvancedLogic}
            className="w-full"
          >
            {actionOptions
              .filter(
                ({ showOnAdvancedLogic }) =>
                  showOnAdvancedLogic || !useAdvancedLogic,
              )
              .map(({ label, value }) => (
                <ToggleButton
                  key={value}
                  disableRipple
                  className={`w-[${Math.round(100.0 / actionOptions.length)}%] gap-3 border-gray-400 py-1 text-sm capitalize text-gray-300 ${
                    selectedActionType === value &&
                    '!border-indigo-600 !bg-indigo-100 !text-indigo-600'
                  }`}
                  value={value}
                  data-testid={`action-component-${value}-record`}
                >
                  <span className="pt-0.5 capitalize">{label}</span>
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
        </div>
      </div>
    </Stack>
  )
}

export default ActionTypeOptions
