import { FC } from 'react'

import { BoltSlashIcon } from '@heroicons/react/24/outline'
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid'
import { Step, StepContent, StepLabel } from '@mui/material'

import AutomationTrackAction from 'src/components/Automations/AutomationTrack/AutomationTrackAction'
import Button from 'src/components/Library/Button/Button'

import { CellAutomation } from '../AutomationCell'
import AutomationEmptyDisplay from '../AutomationEmptyDisplay'
import { SettingsSidebarEditState } from '../AutomationLayout'
import { AdvancedLogicObject } from '../utils'

import AutomationTrackConditional from './AutomationTrackConditional'
import ClearAutomationInputButton, {
  ClearType,
} from './ClearAutomationInputButton'

interface AutomationTrackActionStepProps {
  automation: CellAutomation
  handleOpenEdit: (editMode: SettingsSidebarEditState) => void
}

const AutomationTrackActionStep: FC<AutomationTrackActionStepProps> = ({
  automation,
  handleOpenEdit,
}) => {
  // An empty JSON response is an object, but a saved result is an array of "anything"
  // Check to see if any actions have been saved
  let automationHasActions = false
  let automationActions = []

  if (automation?.action && Object.keys(automation.action)?.length > 0) {
    automationHasActions = true
    automationActions = Object.keys(automation.action)
  }

  return (
    <Step active expanded>
      <StepLabel
        className="px-0"
        icon={
          <ClipboardDocumentCheckIcon className="h-5 w-5 ml-1 text-gray-400" />
        }
      >
        <div className="ml-1 flex gap-4 items-center justify-between">
          <span className="px-3 py-1 text-gray-500 text-xl">Actions</span>
          <ClearAutomationInputButton
            clearItem={ClearType.ACTION}
            automationId={automation.id}
            disabled={
              !automationHasActions || automation?.automationCategoryId === null
            }
          />
        </div>
      </StepLabel>
      <StepContent className="pb-6">
        <div className="pl-4">
          <p className="pb-2 pt-2 font-light text-gray-500 italic">
            Perform the following actions..
          </p>
          {automationHasActions ? (
            <div className="pt-2">
              {automationActions?.map((actionIndex, index) => {
                return (
                  <div key={index}>
                    <AutomationTrackAction
                      key={index}
                      onActionClick={() => {
                        // Only allow open when we have category
                        automation?.automationCategoryId &&
                          handleOpenEdit(SettingsSidebarEditState.ACTION)
                      }}
                      action={automation.action[actionIndex]}
                      actionIndex={index + 1}
                      advancedLogic={
                        automation?.matchCriteria?.[
                          'advancedLogic'
                        ] as AdvancedLogicObject
                      }
                    />
                    {index + 1 !== automationActions.length && (
                      <AutomationTrackConditional text={'THEN'} />
                    )}
                  </div>
                )
              })}
            </div>
          ) : (
            <AutomationEmptyDisplay
              title="No Actions Set"
              subtitle="This automation will make not perform any actions."
              icon={<BoltSlashIcon className="w-16 h-16 pb-4 mx-auto" />}
            />
          )}
          <Button
            disabled={automation?.automationCategoryId === null}
            variant="outlined"
            className="min-w-[0] mt-3 !border-dashed"
            onClick={(event) => {
              event.stopPropagation()
              handleOpenEdit(SettingsSidebarEditState.ACTION)
            }}
            buttonDataTestId="automation-actions-button"
          >
            {automationHasActions ? 'Edit' : 'Add'} Actions
          </Button>
        </div>
      </StepContent>
    </Step>
  )
}

export default AutomationTrackActionStep
