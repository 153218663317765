import { FC } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import { CellAutomation } from '../AutomationCell'

interface AutomationTrackWarningsProps {
  automation: CellAutomation
}

const AutomationTrackWarnings: FC<AutomationTrackWarningsProps> = ({
  automation,
}) => {
  return (
    <div className="max-w-xl mx-auto py-10 flex flex-col gap-10 w-full">
      {!automation?.isValidConfig?.valid && (
        <div className="bg-red-50 border border-red-400 rounded p-4 flex gap-6 items-start">
          <ExclamationCircleIcon className="w-6 h-6 text-red-400" />
          <div>
            <p className="text-red-500">
              There are issues preventing this automation from running.
            </p>
            <ul className="pt-2">
              {automation?.isValidConfig?.requirements?.map((requirement) => (
                <li key={requirement} className="py-1 text-sm">
                  {requirement}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default AutomationTrackWarnings
