import React, { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { automationFieldTypes } from 'api/src/common/automationFieldTypes'

import CustomActionComponentList from 'src/components/Automations/AutomationActions/CustomActionComponentList'
import {
  BaserowField,
  BaserowObject,
  EnrichedActionItemType,
  FilterType,
} from 'src/components/Automations/utils'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button'

import {
  AutocompleteSelectedOption,
  SelectOption,
} from '../AutomationRequirements/AutocompleteComponents'

interface BaserowActionItemComponentProps {
  actionStep: EnrichedActionItemType
  onUpdate: (updatedReq: any, nestIndex?: number) => void
  nestIndex: number
  actionIndex: number
  availableFields: BaserowField[]
  triggerTableFields?: BaserowField[]
}

const BaserowActionStepComponent: React.FC<BaserowActionItemComponentProps> = ({
  actionStep,
  onUpdate,
  nestIndex,
  actionIndex,
  availableFields,
  triggerTableFields,
}) => {
  // Select Field Name
  const [selectedField, setSelectedField] = useState<BaserowObject>(null)
  const [selectedFieldInput, setSelectedFieldInput] = useState<string>('')

  // Select Field Action
  const [fieldAction, setFieldAction] = useState<FilterType>(null)
  const [fieldActionInput, setFieldActionInput] = useState<string>('')

  // Options for the Autocomplete inputs
  const [actionOptions, setActionOptions] = useState<FilterType[]>([])
  const [fieldNameOptions, setFieldNameOptions] = useState<FilterType[]>([])

  const createInputOptions = (selectedField) => {
    const inputOptions = [
      ...(automationFieldTypes.find(
        (option) => option?.value === selectedField?.type,
      )?.inputOptions || []),
    ]

    if (selectedField?.type === 'link_row') {
      inputOptions.push(
        ...Array.from({ length: actionIndex }, (_, i) => ({
          value: `previousRecordId:${i + 1}`,
          label: `Step ${i + 1} Record Id`,
          params: [],
        })),
      )
    }

    return inputOptions
  }

  const handleActionFieldChange = (selectedOption: BaserowObject) => {
    // Find the option in the available fields
    const matchingField = availableFields.find(
      (field) => Number(field.id) === Number(selectedOption.value),
    )

    // Update input state of selected option
    setSelectedField(selectedOption)

    // Prep the data to send up
    const updatedActionItem = {
      ...actionStep,
      fieldName: selectedOption.label,
      fieldType: matchingField.type,
      fieldId: selectedOption.value,
      inputType: { value: null, label: '', params: {} },
    }

    // Send up
    onUpdate(updatedActionItem)

    // Find the action options for the selectedField
    setActionOptions(createInputOptions(matchingField))

    // Reset the action input state because its dependant changed.
    setFieldAction({ value: null, label: '', params: {} })
  }

  const handleActionTypeChange = (selectedOption) => {
    // Set the input state
    setFieldAction(selectedOption)

    // Send the data up
    onUpdate({ ...actionStep, inputType: selectedOption })
  }

  const deleteActionItem = () => {
    onUpdate(null, nestIndex)
  }

  const handleUpdate = (updatedActionStep) => {
    onUpdate(updatedActionStep)
  }

  useEffect(() => {
    // Available Fields Changed - this happens on mount or when the target tables change

    // Get the Field Options
    const fieldOptions = availableFields?.map((field) => ({
      value: String(field.id),
      label: field.name,
      type: field?.type,
      params: [],
    }))

    // Set the options on the field
    setFieldNameOptions(fieldOptions)

    // Set the Saved Field option to the value - if exists
    if (actionStep?.fieldName && actionStep?.fieldType) {
      // Find matching field
      const matchingField = availableFields.find(
        (field) => field.name === actionStep?.fieldName,
      )

      if (!matchingField || matchingField?.type !== actionStep?.fieldType) {
        setSelectedField(null)
        setFieldAction(null)
      } else {
        // Set the field
        setSelectedField({
          value: matchingField?.id,
          label: matchingField?.name,
        })

        // If the input type (method i.e. Set to.. copy from..)
        if (actionStep?.inputType?.value) {
          // Get the action field options
          const actionOptions = createInputOptions(matchingField)

          setActionOptions(actionOptions)

          // Find matching field
          const matchingAction = actionOptions.find(
            (field) => field.value === actionStep?.inputType?.value,
          )

          if (matchingAction) {
            setFieldAction(actionStep?.inputType)
          }
        }
      }
    }
  }, [availableFields, actionStep])

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      className={'flex justify-between border-t border-gray-300 p-4'}
    >
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between gap-4">
          <p className="font-bold">Action {nestIndex + 1}</p>
          <Tooltip title={`Remove Action ${nestIndex + 1}`}>
            <div>
              <Button
                fullWidth={false}
                variant="text"
                className="min-w-[0] rounded-full p-2"
                onClick={() => {
                  deleteActionItem()
                }}
              >
                <XMarkIcon className="h-6 w-6 text-gray-500" />
              </Button>
            </div>
          </Tooltip>
        </div>

        <FormInputRow label="Field">
          <FormControl
            className="w-full"
            disabled={fieldNameOptions?.length === 0}
            error={!selectedField}
          >
            <InputLabel
              htmlFor="select-field-name"
              className="-mt-1.5 text-sm font-light !text-gray-400"
            >
              {fieldNameOptions?.length === 0 && 'No Fields available'}
            </InputLabel>
            <Autocomplete
              disabled={fieldNameOptions?.length === 0}
              value={selectedField}
              options={fieldNameOptions}
              onChange={(_event: any, newValue: any) => {
                handleActionFieldChange(newValue)
              }}
              inputValue={selectedFieldInput}
              onInputChange={(_event, newInputValue) => {
                setSelectedFieldInput(newInputValue)
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value
              }}
              id="select-field-name"
              data-testid="select-field-name"
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="bg-white"
                  error={!selectedField}
                />
              )}
              renderOption={(props, option: SelectOption) => (
                <AutocompleteSelectedOption
                  props={props}
                  option={option}
                  useFieldIcons
                />
              )}
              fullWidth
              size="small"
              disableClearable
            />

            <FormHelperText>
              {!selectedField && 'Select a Field'}
            </FormHelperText>
          </FormControl>
        </FormInputRow>

        {selectedField?.value && (
          <>
            <FormInputRow label="Action">
              <FormControl
                className="w-full"
                disabled={actionOptions?.length === 0}
                error={!fieldAction}
              >
                <InputLabel
                  htmlFor="select-field-action"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {actionOptions?.length === 0 && 'No Actions available'}
                </InputLabel>
                <Autocomplete
                  disabled={actionOptions?.length === 0}
                  value={fieldAction}
                  options={actionOptions}
                  onChange={(_event: any, newValue: any) => {
                    handleActionTypeChange(newValue)
                  }}
                  inputValue={fieldActionInput}
                  onInputChange={(_event, newInputValue) => {
                    setFieldActionInput(newInputValue)
                  }}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                  }}
                  id="select-field-action"
                  data-testid="select-field-action"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        actionOptions?.length > 0 && 'Select an Action'
                      }
                      className="bg-white"
                      error={!fieldAction}
                    />
                  )}
                  fullWidth
                  size="small"
                  disableClearable
                />
                <FormHelperText>
                  {!fieldAction && 'Select an Action'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>

            {fieldAction && (
              <CustomActionComponentList
                selectedInput={fieldAction}
                onUpdate={handleUpdate}
                actionStep={actionStep}
                availableFields={availableFields}
                triggerTableFields={triggerTableFields}
              />
            )}
          </>
        )}
      </div>
    </Stack>
  )
}

export default BaserowActionStepComponent
