import React, { FC } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import { AppBar, Dialog, IconButton, Slide, Toolbar } from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'

import NotificationActionStepComponentEmailMessage from 'src/components/Automations/AutomationActions/NotificationActionStepComponentEmailMessage/NotificationActionStepComponentEmailMessage'
import {
  AutomationUserNotificationDataType,
  BaserowField,
} from 'src/components/Automations/utils'
import Button from 'src/components/Library/Button/Button'

interface EditEmailMessageModalProps {
  openModal: boolean
  handleClose: () => void
  actionStep: AutomationUserNotificationDataType
  handleUpdateMessageText: (value: string) => void
  availableFields: BaserowField[]
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditEmailMessageModal: FC<EditEmailMessageModalProps> = ({
  openModal = false,
  handleClose,
  actionStep,
  handleUpdateMessageText,
  availableFields,
}) => {
  return (
    <Dialog
      fullScreen
      open={openModal}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }} color="transparent" elevation={0}>
        <Toolbar className="justify-between">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <XMarkIcon className="h-6 w-6" />
          </IconButton>

          <Button onClick={handleClose} fullWidth={false}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <div className="h-full p-6 pt-0">
        <NotificationActionStepComponentEmailMessage
          actionStep={actionStep}
          handleUpdateMessageText={handleUpdateMessageText}
          availableFields={availableFields}
          className={'h-full min-h-[450px] border-0'}
        />
      </div>
    </Dialog>
  )
}

export default EditEmailMessageModal
