import React, { Dispatch, FC, SetStateAction } from 'react'

import { Autocomplete, FormControl, TextField } from '@mui/material'

import { SelectOption } from 'src/components/Automations/AutomationRequirements/AutocompleteComponents'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import {
  SelectedLinkFieldRefineValueOption,
  selectedLinkFieldRefineValueOptions,
} from '../utils'

interface RecordSelectorTypeProps {
  handleClearActionItems: () => void
  setSelectedLinkedFieldForFindingSelector: Dispatch<
    SetStateAction<SelectOption>
  >
  setSelectedLinkFieldRefineValue: Dispatch<
    SetStateAction<SelectedLinkFieldRefineValueOption>
  >
  selectedLinkFieldRefineValue: SelectedLinkFieldRefineValueOption
}

const RecordSelectorType: FC<RecordSelectorTypeProps> = ({
  handleClearActionItems,
  setSelectedLinkedFieldForFindingSelector,
  setSelectedLinkFieldRefineValue,
  selectedLinkFieldRefineValue,
}) => {
  // The selected "selector" field. Ie, the selected option that refines all the found linked fields down. For example, last created, first created, etc

  return (
    <FormInputRow label="Refine Records To">
      <FormControl className="w-full">
        <Autocomplete
          value={selectedLinkFieldRefineValue}
          options={selectedLinkFieldRefineValueOptions}
          onChange={(
            _event: React.ChangeEvent,
            newValue: SelectedLinkFieldRefineValueOption,
          ) => {
            setSelectedLinkFieldRefineValue(newValue)
            setSelectedLinkedFieldForFindingSelector(null)
            handleClearActionItems()
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value
          }}
          id="select-Field-Refine-Option"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={'Select a Refinement Method'}
              error={!selectedLinkFieldRefineValue}
            />
          )}
          fullWidth
          size="small"
          disableClearable
        />
      </FormControl>
    </FormInputRow>
  )
}

export default RecordSelectorType
