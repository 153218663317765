import React, { ReactNode, useEffect, useState } from 'react'

import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Tooltip,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import {
  automationFieldTypes,
  FilterOptions,
} from 'api/src/common/automationFieldTypes'
import { useDrag } from 'react-dnd'

import CustomRequirementComponentList from 'src/components/Automations/AutomationRequirements/CustomRequirementComponentList'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'
import Button from 'src/components/Library/Button'

import { BaserowField, FilterType, RequirementType } from '../utils'

import {
  AutocompleteSelectedOption,
  SelectOption,
} from './AutocompleteComponents'

interface RequirementComponentProps {
  requirement: RequirementType
  onUpdate: (updatedReq: RequirementType | null) => void
  availableFields: BaserowField[]
  fieldsToMatch?: BaserowField[]
}

interface FilterOption {
  value: string
  label: string
  params: Record<string, any>
}

export const FieldWrapper: React.FC<{ children: JSX.Element | ReactNode }> = ({
  children,
}) => {
  return <div className="w-full grow">{children}</div>
}

const RequirementComponent: React.FC<RequirementComponentProps> = ({
  requirement,
  onUpdate,
  availableFields,
  fieldsToMatch,
}) => {
  const enrichedAutomationFieldTypes = fieldsToMatch
    ? [
        ...automationFieldTypes.map((fieldTypeData) => ({
          ...fieldTypeData,
          filterOptions: [
            ...fieldTypeData.filterOptions,
            {
              value: 'matches',
              label: 'Matches Field Value',
              params: {
                1: {
                  type: 'dynamic',
                  dataType: 'text',
                  isConditional: false,
                  label: 'Field',
                  value: '',
                },
              },
            },
          ],
        })),
      ]
    : automationFieldTypes

  const [selectedFieldInput, setSelectedFieldInput] = useState<string>('')
  const [selectedField, setSelectedField] = useState<SelectOption>(null)

  // Select Filter Type
  const [selectedFilterInput, setSelectedFilterInput] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<FilterType>(
    requirement.filter,
  )

  // Select Filter Options
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([])

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'REQUIREMENT',
    item: { id: requirement.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const handleFieldChange = (selectedOption: SelectOption) => {
    const selectedField = availableFields.find(
      (field) => field.id === Number(selectedOption.value),
    )

    setSelectedField(selectedOption)
    setSelectedFilter(null)

    onUpdate({
      ...requirement,
      fieldType: selectedField.type,
      fieldName: selectedOption.label,
      fieldId: String(selectedOption.value),
      filter: {
        value: '',
        label: '',
        params: {},
      },
    })
  }

  const handleFilterChange = (selectedOption: FilterType) => {
    setSelectedFilter(selectedOption)
    onUpdate({ ...requirement, filter: selectedOption })
  }

  const deleteRequirement = () => {
    onUpdate(null)
  }

  useEffect(() => {
    if (requirement?.fieldName && availableFields) {
      const selectedField = availableFields.find(
        (field) => field.name === requirement?.fieldName,
      )

      if (!selectedField || selectedField?.type !== requirement?.fieldType) {
        setSelectedField(null)

        setSelectedFilter(null)
      } else {
        setSelectedField({
          value: String(selectedField?.id),
          label: selectedField?.name,
          type: selectedField?.type,
        })

        const optionList: FilterOptions[] = selectedField?.type
          ? enrichedAutomationFieldTypes.find(
              (ft) => ft.value === requirement?.fieldType,
            )?.filterOptions
          : []

        setFilterOptions(optionList)

        if (requirement?.filter) {
          setSelectedFilter(requirement?.filter)
        }
      }
    }
  }, [availableFields])

  return (
    <div
      className="bg-white border border-gray-300 !m-0 rounded "
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="flex items-center justify-between gap-4 border-b px-4 py-2">
        <p className="font-bold">Condition</p>
        <Tooltip title={'Remove Requirement'}>
          <div className="">
            <Button
              fullWidth={false}
              variant="text"
              className="min-w-[0] p-2 rounded-full"
              onClick={deleteRequirement}
            >
              <XMarkIcon className="w-6 h-6 text-gray-500" />
            </Button>
          </div>
        </Tooltip>
      </div>

      <Stack
        direction={'row'}
        spacing={1}
        alignItems="start"
        className="px-4 pt-2"
      >
        {/* Hold for now - might be needed again? */}
        {/* <Stack ref={drag} className="drag-handle cursor-grab pt-2">
          <DoubleVerticalDotsIcon />
        </Stack> */}
        <Stack
          ref={preview}
          direction="row"
          spacing={2}
          alignItems="start"
          className={'pb-4 w-full flex justify-between'}
        >
          <Stack
            direction={'column'}
            spacing={0}
            alignItems="start"
            className={'p-0 w-full flex justify-start grow'}
          >
            <FormInputRow label="Field">
              <FormControl
                className="w-full"
                disabled={availableFields?.length === 0}
                error={!selectedField?.value}
              >
                <InputLabel
                  htmlFor="select-create-Field"
                  className="-mt-1.5 !text-gray-400 font-light text-sm"
                >
                  {availableFields?.length === 0 && 'No Fields available'}
                </InputLabel>
                <Autocomplete
                  value={selectedField}
                  onChange={(_event: any, newValue: SelectOption) => {
                    handleFieldChange(newValue)
                  }}
                  inputValue={selectedFieldInput}
                  onInputChange={(_event, newInputValue) => {
                    setSelectedFieldInput(newInputValue)
                  }}
                  options={availableFields
                    ?.map(
                      (field) =>
                        ({
                          value: String(field.id),
                          label: field.name,
                          type: field.type,
                        }) as SelectOption,
                    )
                    .sort((a, b) =>
                      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                    )}
                  size="small"
                  id="select-field"
                  disableClearable
                  fullWidth
                  isOptionEqualToValue={(option, value) =>
                    option?.value === value?.value
                  }
                  disabled={availableFields?.length === 0}
                  renderOption={(props, option: SelectOption) => (
                    <AutocompleteSelectedOption
                      props={props}
                      option={option}
                      useFieldIcons
                    />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!selectedField?.value}
                      placeholder={
                        availableFields?.length && !selectedField?.value
                          ? 'Select a Field'
                          : ''
                      }
                    />
                  )}
                />
                <FormHelperText>
                  {!selectedField?.value && 'Select a Field'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
            <FormInputRow label="Filter">
              <Stack
                direction={'row'}
                spacing={1}
                className={'w-full'}
                alignItems={'center'}
              >
                <FormControl
                  className="w-full"
                  disabled={filterOptions?.length === 0}
                  error={!selectedFilter?.value}
                >
                  <InputLabel
                    htmlFor="select-create-Filter"
                    className="-mt-1.5 !text-gray-400 font-light text-sm"
                  >
                    {filterOptions?.length === 0 && 'No Filters available'}
                  </InputLabel>
                  <Autocomplete
                    value={selectedFilter}
                    onChange={(_event: any, newValue: FilterType) => {
                      handleFilterChange(newValue)
                    }}
                    inputValue={selectedFilterInput}
                    onInputChange={(_event, newInputValue) => {
                      setSelectedFilterInput(newInputValue)
                    }}
                    options={filterOptions}
                    size="small"
                    id="select-filed"
                    disableClearable
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value?.value
                    }
                    disabled={filterOptions?.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!selectedFilter?.value}
                        placeholder={
                          filterOptions?.length && !selectedFilter?.value
                            ? 'Select a Filter'
                            : ''
                        }
                      />
                    )}
                  />
                  <FormHelperText>
                    {!selectedFilter?.value && 'Select a Filter'}
                  </FormHelperText>
                </FormControl>
                {selectedFilter?.value === 'matches' && (
                  <Tooltip
                    title={
                      'This feature is currently in testing. Matching fields of different field types may yield unexpected results.'
                    }
                  >
                    <div>
                      <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500" />
                    </div>
                  </Tooltip>
                )}
              </Stack>
            </FormInputRow>

            {selectedFilter && (
              <CustomRequirementComponentList
                selectedFilter={selectedFilter}
                onUpdate={onUpdate}
                requirement={requirement}
                availableFields={availableFields}
                fieldsToMatch={fieldsToMatch}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  )
}

export default RequirementComponent
