import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { List, ListItem, ListItemButton, Paper } from '@mui/material'
import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'

import type { MentionSuggestion } from './mentionSuggestionOptions'

export type SuggestionListRef = {
  // For convenience using this SuggestionList from within the
  // mentionSuggestionOptions, we'll match the signature of SuggestionOptions's
  // `onKeyDown` returned in its `render` function
  onKeyDown: NonNullable<
    ReturnType<
      NonNullable<SuggestionOptions<MentionSuggestion>['render']>
    >['onKeyDown']
  >
}

// This type is based on
// https://github.com/ueberdosis/tiptap/blob/a27c35ac8f1afc9d51f235271814702bc72f1e01/packages/extension-mention/src/mention.ts#L73-L103.
// TODO(Steven DeMartini): Use the Tiptap exported MentionNodeAttrs interface
// once https://github.com/ueberdosis/tiptap/pull/4136 is merged.
interface MentionNodeAttrs {
  id: string | null
  label?: string | null
}

export type SuggestionListProps = SuggestionProps<MentionSuggestion>

const SuggestionList = forwardRef<SuggestionListRef, SuggestionListProps>(
  (props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = (index: number) => {
      if (index >= props.items.length) {
        return
      }

      const suggestion = props.items[index]
      const mentionItem: MentionNodeAttrs = {
        id: suggestion.id,
        label: suggestion.mentionLabel,
      }

      // Insert the mention in the format @name<id>
      const mentionText = `[${mentionItem.label}](${mentionItem.id})`

      // Update the command to insert the mentionText without extra '@'
      props.command({
        id: mentionItem.id,
        label: mentionText, // Insert with the @name<id> format
      })
    }

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      )
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (event.key === 'Enter') {
          enterHandler()
          return true
        }

        return false
      },
    }))

    return props.items.length > 0 ? (
      <Paper elevation={5}>
        <List
          dense
          sx={{
            overflow: 'scroll',
            maxHeight: '200px',
          }}
        >
          {props.items.map((item, index) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                selected={index === selectedIndex}
                onClick={() => selectItem(index)}
              >
                {item.mentionLabel}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    ) : null
  },
)

SuggestionList.displayName = 'SuggestionList'

export default SuggestionList
