import React from 'react'

import { FormHelperText } from '@mui/material'
import { Mention, MentionsInput } from 'react-mentions'

import {
  AutomationUserNotificationDataType,
  BaserowField,
} from 'src/components/Automations/utils'

interface NotificationActionStepComponentNotificationMessageProps {
  actionStep: AutomationUserNotificationDataType
  handleUpdateMessageText: (value: string) => void
  availableFields: BaserowField[]
}

const NotificationActionStepComponentNotificationMessage: React.FC<
  NotificationActionStepComponentNotificationMessageProps
> = ({ actionStep, handleUpdateMessageText, availableFields }) => {
  return (
    <>
      <MentionsInput
        value={actionStep?.notificationMessage}
        onChange={(event) => {
          handleUpdateMessageText(event.target.value)
        }}
        forceSuggestionsAboveCursor
        allowSpaceInQuery
        placeholder="Notification Message -> Use @ To Insert A Field Value."
        className="h-auto min-h-[8rem] w-full rounded border-2 border-gray-300 bg-white p-3.5"
        style={{
          input: {
            padding: '15px',
            minHeight: '4em', // Sets minimum height to 4 rows
            lineHeight: '1.5', // Adjusts line height for better spacing
          },
          suggestions: {
            list: {
              borderRadius: '5px',
              border: '1px solid #9ca3af',
              padding: '5px',
              zIndex: 9999, // Ensures the suggestions list appears on top
              maxHeight: '150px', // Set the maximum height for the list
              overflowY: 'auto', // Enable scrolling when content exceeds max height
            },
            item: {
              padding: '5px',
              borderBottom: '1px solid #f3f4f6',
              '&focused': {
                backgroundColor: '#f3f4f6',
              },
              fontSize: '12px',
            },
          },
        }}
      >
        <Mention
          trigger="@"
          data={availableFields.map((field) => ({
            id: field.id,
            display: field.name,
          }))}
          displayTransform={(_, display) => `@${display}`}
        />
      </MentionsInput>
      <FormHelperText>
        {!actionStep?.notificationMessage && 'Enter a notification message'}
      </FormHelperText>
    </>
  )
}

export default NotificationActionStepComponentNotificationMessage
