import React, { Dispatch, FC, SetStateAction } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import { AutocompleteSelectedOption } from '../AutomationRequirements/AutocompleteComponents'
import {
  LinkedFieldForFindType,
  RequirementGroupType,
  SelectedLinkFieldRefineValueOption,
} from '../utils'

interface LinkedFieldSelectorProps {
  handleClearActionItems: () => void
  setAdvancedLogicFilterRequirements: Dispatch<
    SetStateAction<RequirementGroupType[]>
  >
  setSelectedLinkFieldRefineValue: Dispatch<
    SetStateAction<SelectedLinkFieldRefineValueOption>
  >
  setSelectedLinkedField: Dispatch<SetStateAction<LinkedFieldForFindType>>
  selectedLinkedField: LinkedFieldForFindType
  linkedTableOptions: LinkedFieldForFindType[]
}

const LinkedFieldSelector: FC<LinkedFieldSelectorProps> = ({
  handleClearActionItems,
  setAdvancedLogicFilterRequirements,
  setSelectedLinkFieldRefineValue,
  setSelectedLinkedField,
  selectedLinkedField,
  linkedTableOptions,
}) => {
  return (
    <FormInputRow label="Linked Fields">
      <FormControl
        className="w-full"
        disabled={linkedTableOptions?.length === 0}
        error={!selectedLinkedField?.value}
      >
        <InputLabel
          htmlFor="select-Field-Option"
          className="-mt-1.5 !text-gray-400 font-light text-sm"
        >
          {linkedTableOptions?.length === 0 &&
            !selectedLinkedField &&
            'No Options available'}
        </InputLabel>
        <Autocomplete
          disabled={linkedTableOptions?.length === 0}
          value={selectedLinkedField}
          options={linkedTableOptions}
          onChange={(
            _event: React.ChangeEvent,
            newValue: LinkedFieldForFindType,
          ) => {
            setSelectedLinkedField(newValue)
            setAdvancedLogicFilterRequirements([])
            setSelectedLinkFieldRefineValue(null)
            handleClearActionItems()
          }}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.value === value.value
          }}
          id="select-Field-Option"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                linkedTableOptions?.length > 0 && 'Select a Linked Field'
              }
              error={!selectedLinkedField}
            />
          )}
          renderOption={(props, option: LinkedFieldForFindType) => (
            <AutocompleteSelectedOption
              props={props}
              option={{
                value: option.value.toString(),
                label: option.label,
                type: option.type,
              }}
              useFieldIcons
            />
          )}
          fullWidth
          size="small"
          disableClearable
        />
        <FormHelperText>
          {!selectedLinkedField?.value && 'Select a Linked Field'}
        </FormHelperText>
      </FormControl>
    </FormInputRow>
  )
}

export default LinkedFieldSelector
