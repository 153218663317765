import React, { FC, useEffect, useState } from 'react'

import { ExclamationTriangleIcon, UserIcon } from '@heroicons/react/24/outline'
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { overrideFieldTypeCompatibilities } from 'api/src/common/automationFieldTypes'
import dayjs from 'dayjs'

import {
  BaserowCollaborator,
  BaserowField,
  BaserowSelectOption,
  selectColorConverter,
} from 'src/components/Automations/utils'
import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import {
  AutocompleteSelectedOption,
  SelectOption,
} from '../AutomationRequirements/AutocompleteComponents'

type ParamType = {
  value: string
  label: string
  dataType: string
  type: string
  isConditional?: boolean
  dependency?: Record<string, any>
  copyType?: string
  options?: string[]
}

const fieldSources = [
  { value: 'trigger', label: 'Trigger Record' },
  { value: 'action', label: 'This Record' },
]

interface CustomInputComponentProps {
  paramKey: string
  paramValue: ParamType
  handleChange: (
    key: string,
    value: any,
    valueLabel: string,
    copyType?: string,
  ) => void
  allParams: Record<string, any>
  selectOptions?: BaserowSelectOption[]
  triggerTableFields?: BaserowField[]
  actionTableFields?: BaserowField[]
  collaborators?: BaserowCollaborator[]
}

const CustomActionComponent: FC<CustomInputComponentProps> = ({
  paramKey,
  paramValue,
  handleChange,
  allParams,
  selectOptions = [],
  triggerTableFields,
  actionTableFields,
  collaborators,
}) => {
  // FORM INPUTS

  // Copy From Field
  const [selectedTriggerSourceField, setSelectedTriggerSourceField] =
    useState<SelectOption>(null)

  // Copy Source
  const [selectedFieldSource, setSelectedFieldSource] = useState<string>(
    paramValue?.copyType,
  )

  // Custom Option Input
  const [customOptionInput, setCustomOptionInput] = useState<string>('')

  const [triggerFieldOptionsFiltered, setTriggerFieldOptionsFiltered] =
    useState([])
  const [actionFieldOptionsFiltered, setActionFieldOptionsFiltered] = useState(
    [],
  )
  const [selectOptionsFormatted, setSelectOptionsFormatted] = useState([])
  const [collaboratorOptionsFormatted, setCollaboratorOptionsFormatted] =
    useState([])
  const [enrichedParamData, setEnrichedParamData] = useState(null)

  const [triggerFieldOptions, setTriggerFieldOptions] = useState([])
  const [actionFieldOptions, setActionFieldOptions] = useState([])
  const [customOptions, setCustomOptions] = useState(null)

  const triggerOptions =
    selectedFieldSource === 'trigger'
      ? triggerFieldOptionsFiltered
      : actionFieldOptionsFiltered

  const findCollaboratorName = (values) => {
    const foundCollaborators = collaboratorOptionsFormatted
      .filter((option) => values.includes(option.value))
      .map((collaborator) => collaborator.label)

    return foundCollaborators?.join(', ')
  }

  useEffect(() => {
    // Set some states for input values

    // If we are copying - set state based on saved data
    if (enrichedParamData?.type === 'copy') {
      // Recollect the options on mount
      const filteredActionFieldOptions = actionFieldOptions.filter(
        (option) =>
          option.type === paramValue?.dataType ||
          overrideFieldTypeCompatibilities[paramValue?.dataType]?.includes(
            option.type,
          ),
      )
      const filteredTriggerFieldOptions = triggerFieldOptions.filter(
        (option) =>
          option.type === paramValue?.dataType ||
          overrideFieldTypeCompatibilities[paramValue?.dataType]?.includes(
            option.type,
          ),
      )

      // Determine trigger type
      const triggerOptions =
        enrichedParamData?.copyType === 'trigger'
          ? filteredTriggerFieldOptions
          : filteredActionFieldOptions

      // Find selected option
      const foundItem = triggerOptions.find(
        (item) => item.value === enrichedParamData?.value,
      )

      setSelectedTriggerSourceField(foundItem ?? null)
    }

    if (enrichedParamData?.type === 'options') {
      setCustomOptions(
        enrichedParamData.options.map((option) => {
          return {
            value: option,
            label: option,
          }
        }),
      )
      setCustomOptionInput(enrichedParamData?.value ?? '')
    }
  }, [enrichedParamData, triggerFieldOptions, actionFieldOptions])

  useEffect(() => {
    // Enrich the Param Data
    if (paramValue?.isConditional) {
      const dependency = paramValue?.dependency
      const dependentValue = allParams[dependency.reference]?.value
      const condition = dependency?.conditions[dependentValue]

      setEnrichedParamData({
        ...paramValue,
        dataType: condition?.dataType,
        type: 'input',
        label: dependentValue,
      })
    } else {
      setEnrichedParamData(paramValue)
    }

    // Set custom option
  }, [paramValue, enrichedParamData])

  // If custom options change - update options
  useEffect(() => {
    if (selectOptions) {
      setSelectOptionsFormatted(
        selectOptions.map((option) => {
          return {
            value: option.id,
            label: option.value,
            color: option.color,
          }
        }),
      )
    }
  }, [selectOptions])

  // If collaborators change - update options
  useEffect(() => {
    if (collaborators) {
      setCollaboratorOptionsFormatted(
        collaborators.map((option) => {
          return {
            value: option.user_id,
            label: option.name,
          }
        }),
      )
    }
  }, [collaborators])

  useEffect(() => {
    const options = actionTableFields.map((field) => {
      return {
        value: field.id,
        label: field.name,
        type: field.type,
      }
    })
    setActionFieldOptions(options)
  }, [actionTableFields])

  useEffect(() => {
    const options = triggerTableFields.map((field) => {
      return {
        value: field.id,
        label: field.name,
        type: field.type,
      }
    })
    setTriggerFieldOptions(options)
  }, [triggerTableFields])

  useEffect(() => {
    // Get the options
    const filteredActionFieldOptions = actionFieldOptions.filter(
      (option) =>
        option.type === paramValue?.dataType ||
        overrideFieldTypeCompatibilities[paramValue?.dataType]?.includes(
          option.type,
        ),
    )

    const filteredTriggerFieldOptions = triggerFieldOptions.filter(
      (option) =>
        option.type === paramValue?.dataType ||
        overrideFieldTypeCompatibilities[paramValue?.dataType]?.includes(
          option.type,
        ),
    )

    // Sort and save
    setTriggerFieldOptionsFiltered(
      [...filteredTriggerFieldOptions].sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      ),
    )
    setActionFieldOptionsFiltered(
      [...filteredActionFieldOptions].sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
      ),
    )
  }, [triggerFieldOptions, actionFieldOptions, selectedFieldSource])

  useEffect(() => {
    // Reset when the field source change
    setSelectedTriggerSourceField(null)
  }, [selectedFieldSource])

  return (
    <Stack className={'w-full'}>
      {enrichedParamData?.type === 'copy' && (
        <Stack className={'w-full'} direction={'column'}>
          <FormInputRow label="Source">
            <FormControl
              className="w-full"
              disabled={fieldSources?.length === 0}
              error={!selectedFieldSource}
            >
              <InputLabel
                htmlFor="select-field-source"
                className="-mt-1.5 text-sm font-light !text-gray-400"
              >
                {fieldSources?.length === 0 && 'No Sources available'}
              </InputLabel>
              <Select
                id="select-field-source"
                className="bg-white"
                fullWidth
                size="small"
                disabled={fieldSources?.length === 0}
                value={selectedFieldSource}
                onChange={(event: SelectChangeEvent) => {
                  setSelectedFieldSource(event.target.value)
                }}
              >
                {fieldSources?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {!selectedFieldSource && 'Select a Source'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>

          {selectedFieldSource && (
            <>
              <FormInputRow label="Trigger Field">
                <FormControl
                  className="w-full"
                  disabled={triggerOptions?.length === 0}
                  error={!selectedTriggerSourceField}
                >
                  <InputLabel
                    htmlFor="select-field-value"
                    className="-mt-1.5 text-sm font-light !text-gray-400"
                  >
                    {triggerOptions?.length === 0 && 'No Fields available'}
                  </InputLabel>

                  <Stack
                    direction="row"
                    spacing={2}
                    className={'w-full'}
                    alignItems={'center'}
                  >
                    <Autocomplete
                      className="bg-white"
                      fullWidth={true}
                      value={selectedTriggerSourceField}
                      options={triggerOptions}
                      onChange={(
                        _event: React.ChangeEvent,
                        newValue: SelectOption,
                      ) => {
                        setSelectedTriggerSourceField(newValue)
                        handleChange(
                          paramKey,
                          newValue.value,
                          newValue.label,
                          selectedFieldSource,
                        )
                      }}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value
                      }}
                      id="select-Field-Option"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            triggerOptions?.length > 0 &&
                            'Select a Trigger Field'
                          }
                          error={!selectedTriggerSourceField}
                        />
                      )}
                      renderOption={(props, option: SelectOption) => (
                        <AutocompleteSelectedOption
                          props={props}
                          option={{
                            value: option.value.toString(),
                            label: option.label,
                            type: option.type,
                          }}
                          useFieldIcons
                        />
                      )}
                      size="small"
                      disableClearable
                    />
                    {paramValue?.dataType &&
                      selectedTriggerSourceField?.type &&
                      paramValue?.dataType !==
                        selectedTriggerSourceField?.type && (
                        <Tooltip
                          title={`You are copying from a field of type "${selectedTriggerSourceField?.type.replace('_', ' ')}" to a field of type "${paramValue?.dataType.replace('_', ' ')}". Please make sure the data entered into these fields are compatible.`}
                        >
                          <ExclamationTriangleIcon className="!h-6 !w-6 text-orange-500" />
                        </Tooltip>
                      )}
                  </Stack>
                  <FormHelperText>
                    {!selectedTriggerSourceField && 'Select a Trigger Field'}
                  </FormHelperText>
                </FormControl>
              </FormInputRow>
            </>
          )}
        </Stack>
      )}

      {enrichedParamData?.type === 'input' && (
        <>
          {enrichedParamData?.dataType === 'single_select' && (
            <FormInputRow label="Value">
              <FormControl
                className="w-full"
                disabled={selectOptionsFormatted?.length === 0}
                error={!enrichedParamData?.value}
              >
                <InputLabel
                  htmlFor="select-field-value"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {selectOptionsFormatted?.length === 0 &&
                    'No Values available'}
                </InputLabel>
                <Select
                  id="select-field-value"
                  className="bg-white"
                  fullWidth
                  size="small"
                  disabled={selectOptionsFormatted?.length === 0}
                  value={enrichedParamData?.value}
                  onChange={(event: SelectChangeEvent) => {
                    const matchedOption = selectOptionsFormatted.find(
                      (option) => option.value === event.target.value,
                    )
                    handleChange(
                      paramKey,
                      matchedOption.value,
                      matchedOption.label,
                    )
                  }}
                >
                  {selectOptionsFormatted?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div className="flex items-center gap-2">
                        {option?.color && (
                          <div
                            className="h-5 w-5 rounded"
                            style={{
                              backgroundColor: selectColorConverter(
                                option.color,
                              ),
                            }}
                          ></div>
                        )}

                        <p className="pt-0.5">{option.label}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {!enrichedParamData?.value && 'Select a Value'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
          )}

          {enrichedParamData?.dataType === 'multiple_select' && (
            <FormInputRow label="Value">
              <FormControl
                className="w-full"
                disabled={selectOptionsFormatted?.length === 0}
                error={enrichedParamData?.value?.length === 0}
              >
                <InputLabel
                  htmlFor="select-field-value"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {selectOptionsFormatted?.length === 0 &&
                    'No Values available'}
                </InputLabel>
                <Select
                  id="select-field-value"
                  multiple
                  className="bg-white"
                  fullWidth
                  size="small"
                  disabled={selectOptionsFormatted?.length === 0}
                  value={
                    enrichedParamData?.value === ''
                      ? []
                      : enrichedParamData?.value
                  }
                  onChange={(event) => {
                    const values: number[] = event.target.value
                    const matchingOptions = selectOptionsFormatted.filter(
                      (option) => values.includes(option.value),
                    )
                    handleChange(
                      paramKey,
                      matchingOptions.map((option) => option.value),
                      matchingOptions.map((option) => option.label).join(', '),
                    )
                  }}
                  renderValue={() => {
                    return (
                      <p className="line-clamp-1 max-w-[470px]">
                        {enrichedParamData?.valueLabel}
                      </p>
                    )
                  }}
                >
                  {selectOptionsFormatted?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div className="flex items-center gap-2">
                        {option?.color && (
                          <div
                            className="h-5 w-5 rounded"
                            style={{
                              backgroundColor: selectColorConverter(
                                option.color,
                              ),
                            }}
                          ></div>
                        )}

                        <p className="pt-0.5">{option.label}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {enrichedParamData?.value?.length === 0 && 'Select a Value'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
          )}

          {enrichedParamData?.dataType === 'multiple_collaborators' && (
            <FormInputRow label="Value">
              <FormControl
                className="w-full"
                disabled={collaboratorOptionsFormatted?.length === 0}
                error={enrichedParamData?.value?.length === 0}
              >
                <InputLabel
                  htmlFor="select-field-value"
                  className="-mt-1.5 text-sm font-light !text-gray-400"
                >
                  {collaboratorOptionsFormatted?.length === 0 &&
                    'No Values available'}
                </InputLabel>

                <Select
                  id="select-field-value"
                  multiple
                  className="line-clamp-1 bg-white"
                  fullWidth
                  size="small"
                  disabled={collaboratorOptionsFormatted?.length === 0}
                  value={
                    enrichedParamData?.value === ''
                      ? []
                      : enrichedParamData?.value
                  }
                  onChange={(event: SelectChangeEvent) => {
                    const matchingOptions = collaboratorOptionsFormatted.filter(
                      (option) => event.target.value.includes(option.value),
                    )
                    handleChange(
                      paramKey,
                      matchingOptions.map((option) => option.value),
                      matchingOptions.map((option) => option.label).join(', '),
                    )
                  }}
                  renderValue={() => (
                    <p className="line-clamp-1 max-w-[470px]">
                      {findCollaboratorName(enrichedParamData?.value)}
                    </p>
                  )}
                >
                  {collaboratorOptionsFormatted?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div className="flex items-center gap-2">
                        <UserIcon className="h-4 w-4" />
                        <p className="pt-0.5">{option.label}</p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {enrichedParamData?.value?.length === 0 && 'Select a Value'}
                </FormHelperText>
              </FormControl>
            </FormInputRow>
          )}

          {(enrichedParamData?.dataType === 'text' ||
            enrichedParamData?.dataType === 'url' ||
            enrichedParamData?.dataType === 'formula' ||
            enrichedParamData?.dataType === 'email' ||
            enrichedParamData?.dataType === 'phone_number' ||
            enrichedParamData?.dataType === 'long_text') && (
            <FormInputRow label="Value">
              <TextField
                data-testid="custom-action-text-field"
                placeholder={enrichedParamData.label}
                variant="outlined"
                className="w-full bg-white"
                value={enrichedParamData.value}
                onChange={(e) =>
                  handleChange(paramKey, e.target.value, e.target.value)
                }
                size={'small'}
                error={!enrichedParamData?.value}
                helperText={!enrichedParamData?.value && 'Enter a value'}
                sx={{
                  '& .MuiFormHelperText-root': {
                    backgroundColor: '#f9fafb',
                    margin: 0,
                    padding: '4px 14px',
                  },
                }}
              />
            </FormInputRow>
          )}

          {enrichedParamData?.dataType === 'date' && (
            <FormInputRow label="Value">
              <DatePicker
                className="w-full bg-white"
                value={dayjs(enrichedParamData?.value)}
                onChange={(newValue) =>
                  handleChange(
                    paramKey,
                    newValue.toISOString(),
                    newValue.toISOString(),
                  )
                }
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    id: 'startDate',
                    name: 'startDate',
                    placeholder: enrichedParamData?.label,
                    error: !enrichedParamData?.value,
                  },
                }}
              />
            </FormInputRow>
          )}

          {enrichedParamData?.dataType === 'boolean' && (
            <FormInputRow label="Value">
              <Switch
                checked={enrichedParamData?.value === 'true'}
                onChange={(e) => {
                  handleChange(
                    paramKey,
                    e.target.checked.toString(),
                    e.target.checked.toString(),
                  )
                }}
              />
            </FormInputRow>
          )}

          {enrichedParamData.dataType === 'float' && (
            <FormInputRow label="Value">
              <TextField
                placeholder={enrichedParamData.label}
                variant="outlined"
                value={enrichedParamData?.value ?? ''}
                onChange={(e) =>
                  handleChange(
                    paramKey,
                    parseFloat(e.target.value),
                    parseFloat(e.target.value).toString(),
                  )
                }
                type="number"
                size={'small'}
                className="w-full bg-white"
                error={!enrichedParamData?.value}
                helperText={!enrichedParamData?.value && 'Enter a value'}
                sx={{
                  '& .MuiFormHelperText-root': {
                    backgroundColor: '#f9fafb',
                    margin: 0,
                    padding: '4px 14px',
                  },
                }}
              />
            </FormInputRow>
          )}

          {(enrichedParamData?.dataType === 'integer' ||
            enrichedParamData?.dataType === 'rating' ||
            enrichedParamData?.dataType === 'duration') && (
            <FormInputRow label="Value">
              <TextField
                placeholder={
                  enrichedParamData?.label ? enrichedParamData.label : 0
                }
                variant="outlined"
                className="w-full bg-white"
                value={enrichedParamData?.value ?? ''}
                onChange={(e) => {
                  const newValue = parseInt(e.target.value, 10)
                  if (!isNaN(newValue)) {
                    handleChange(paramKey, newValue, newValue.toString())
                  }
                }}
                type="number"
                size="small"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                error={!enrichedParamData?.value}
                helperText={!enrichedParamData?.value && 'Enter a value'}
                sx={{
                  '& .MuiFormHelperText-root': {
                    backgroundColor: '#f9fafb',
                    margin: 0,
                    padding: '4px 14px',
                  },
                }}
              />
            </FormInputRow>
          )}

          {enrichedParamData?.dataType === 'number' && (
            <FormInputRow label="Value">
              <TextField
                placeholder={
                  enrichedParamData?.label ? enrichedParamData.label : 0
                }
                variant="outlined"
                value={enrichedParamData?.value ?? ''}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value)
                  if (!isNaN(newValue)) {
                    handleChange(paramKey, newValue, newValue.toString())
                  }
                }}
                type="number"
                className="w-full bg-white"
                size="small"
                error={!enrichedParamData?.value}
                helperText={!enrichedParamData?.value && 'Enter a value'}
                sx={{
                  '& .MuiFormHelperText-root': {
                    backgroundColor: '#f9fafb',
                    margin: 0,
                    padding: '4px 14px',
                  },
                }}
              />
            </FormInputRow>
          )}
        </>
      )}

      {enrichedParamData?.type === 'options' && (
        <>
          <FormInputRow label="Option">
            <FormControl
              className="w-full"
              disabled={customOptions?.length === 0}
              error={!customOptionInput}
            >
              <InputLabel
                htmlFor="select-custom-value"
                className="-mt-1.5 text-sm font-light !text-gray-400"
              >
                {customOptions?.length === 0 && 'No Values available'}
              </InputLabel>
              <Select
                disabled={customOptions?.length === 0}
                value={customOptionInput}
                onChange={(event: SelectChangeEvent) => {
                  setCustomOptionInput(event.target.value)
                  handleChange(paramKey, event.target.value, event.target.value)
                }}
                id="select-custom-value"
                className="bg-white"
                fullWidth
                size="small"
              >
                {customOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {!customOptionInput && 'Select a Value'}
              </FormHelperText>
            </FormControl>
          </FormInputRow>
        </>
      )}
    </Stack>
  )
}

export default CustomActionComponent
