import { FC } from 'react'

interface AutomationEmptyDisplayProps {
  title: string
  subtitle: string
  icon: JSX.Element
}

const AutomationEmptyDisplay: FC<AutomationEmptyDisplayProps> = ({
  title,
  subtitle,
  icon,
}) => {
  return (
    <div className="mt-6 font-light text-center py-10 text-lg text-gray-500 italic">
      {icon}
      <p>{title}</p>
      <p className="text-md">{subtitle}</p>
    </div>
  )
}

export default AutomationEmptyDisplay
