export type AutomationFieldType = {
  value: string
  label: string
  inputOptions: {
    value: string
    label: string
    params: Record<string, any>
  }[]
  filterOptions: FilterOptions[]
}

export type FilterOptions = {
  value: string
  label: string
  params: Record<string, any>
}

export const overrideFieldTypeCompatibilities = {
  single_select: ['lookup'],
  multiple_select: ['lookup'],
  date: ['lookup', 'formula'],
  link_row: ['lookup'],
}

export const automationFieldTypes: AutomationFieldType[] = [
  {
    value: 'text',
    label: 'Single Line Text',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
            numSteps: 1,
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'long_text',
    label: 'Multi Line Text',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'long_text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'long_text',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'long_text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'long_text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'long_text',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'link_row',
    label: 'Linked Field',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      { value: 'recordId', label: 'Trigger Record Id', params: {} },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'link_row',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
    ],
  },
  {
    value: 'lookup',
    label: 'Lookup Field',
    inputOptions: [
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'lookup',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
    ],
  },
  {
    value: 'formula',
    label: 'Formula',
    inputOptions: [
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'formula',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'formula',
            isConditional: false,
            label: 'Evaluated Formula Value',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'formula',
            isConditional: false,
            label: 'Evaluated Formula Value',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'formula',
            isConditional: false,
            label: 'Evaluated Formula Value',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'formula',
            isConditional: false,
            label: 'Evaluated Formula Value',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'boolean',
    label: 'Checkbox',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'boolean',
            isConditional: false,
            label: 'Input',
            value: false,
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'boolean',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isTrue', label: 'Is True', params: {} },

      { value: 'isFalse', label: 'Is False', params: {} },
    ],
  },
  {
    value: 'number',
    label: 'Number',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'number',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },

      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'currency',
    label: 'Currency',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'currency',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },

      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'currency',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'percent',
    label: 'Percent',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Input',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'percent',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },

      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'percent',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'rating',
    label: 'Rating',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Rating',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'rating',
            isConditional: false,
            label: 'Rating',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },

      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Rating',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Rating',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'rating',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'count',
    label: 'Count',
    inputOptions: [
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'count',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },

      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'count',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'phone_number',
    label: 'Phone Number',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: '',
            numSteps: 1,
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'phone_number',
            isConditional: false,
            label: 'Phone Number',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'email',
    label: 'Email',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: '',
            numSteps: 1,
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'email',
            isConditional: false,
            label: 'Email',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'url',
    label: 'URL',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: '',
            numSteps: 1,
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: '',
          },
        },
      },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: '',
          },
        },
      },
      {
        value: 'contain',
        label: 'Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: '',
          },
        },
      },
      {
        value: 'notContain',
        label: 'Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'url',
            isConditional: false,
            label: 'URL',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'duration',
    label: 'Duration',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'duration',
            isConditional: false,
            label: 'Seconds',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'duration',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isZeroOrEmpty', label: 'Is Zero Or Empty', params: {} },

      { value: 'isNotZeroOrEmpty', label: 'Is Not Zero Or Empty', params: {} },

      {
        value: 'isEqualTo',
        label: 'Is Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThan',
        label: 'Is Greater Than',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isGreaterThanOrEqualTo',
        label: 'Is Greater Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThan',
        label: 'Is Less Than',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },

      {
        value: 'isLessThanOrEqualTo',
        label: 'Is Less Than Or Equal To',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'number',
            isConditional: false,
            label: 'Number',
            value: 0,
          },
        },
      },
    ],
  },
  {
    value: 'date',
    label: 'Date',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: ['Exact Date', 'Current Date'],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
              },
            },
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'date',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'isWithin',
        label: 'Is Within',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrBefore',
        label: 'On Or Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrAfter',
        label: 'On Or After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'before',
        label: 'Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'after',
        label: 'After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
    ],
  },
  {
    value: 'single_select',
    label: 'Single Select',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'single_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'single_select',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'input',
            dataType: 'single_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'isNot',
        label: 'Is Not',
        params: {
          1: {
            type: 'input',
            dataType: 'single_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },

      {
        value: 'isNoneOf',
        label: 'Is None Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'isAnyOf',
        label: 'Is Any Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'multiple_select',
    label: 'Multiple Select',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
      {
        value: 'isNoneOf',
        label: 'Has None Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'hasAllOf',
        label: 'Has All Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'hasAnyOf',
        label: 'Has Any Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'hasExactly',
        label: 'Has Exactly',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_select',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'multiple_collaborators',
    label: 'Collaborators',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      { value: 'isNotEmpty', label: 'Is Not Empty', params: {} },
      {
        value: 'isNoneOf',
        label: 'Is None Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'isAnyOf',
        label: 'Is Any Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'isAllOf',
        label: 'Is All Of',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
      {
        value: 'isExactly',
        label: 'Is Exactly',
        params: {
          1: {
            type: 'input',
            dataType: 'multiple_collaborators',
            isConditional: false,
            label: 'Options',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'file',
    label: 'File',
    inputOptions: [
      { value: 'clear', label: 'Clear', params: {} },
      {
        value: 'copyFrom',
        label: 'Copy From',
        params: {
          1: {
            type: 'copy',
            dataType: 'file',
            isConditional: false,
            label: 'Input',
            value: null,
            copyType: null,
            numSteps: 2,
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'isNotEmpty',
        label: 'Is Not Empty',
        params: {},
      },
      { value: 'isEmpty', label: 'Is Empty', params: {} },
      {
        value: 'fileNameContain',
        label: 'File Name Contains',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'File Name',
            value: '',
          },
        },
      },
      {
        value: 'notFileNameContain',
        label: 'File Name Does Not Contain',
        params: {
          1: {
            type: 'input',
            dataType: 'text',
            isConditional: false,
            label: 'File Name',
            value: '',
          },
        },
      },
    ],
  },
  {
    value: 'created_on',
    label: 'Created On',
    inputOptions: [
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: ['Exact Date', 'Current Date'],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
              },
            },
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'isWithin',
        label: 'Is Within',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrBefore',
        label: 'On Or Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrAfter',
        label: 'On Or After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'before',
        label: 'Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'after',
        label: 'After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
    ],
  },
  {
    value: 'last_modified',
    label: 'Last Modified',
    inputOptions: [
      {
        value: 'setTo',
        label: 'Set To',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: ['Exact Date', 'Current Date'],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
              },
            },
          },
        },
      },
    ],
    filterOptions: [
      {
        value: 'is',
        label: 'Is',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'isWithin',
        label: 'Is Within',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrBefore',
        label: 'On Or Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'onOrAfter',
        label: 'On Or After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'before',
        label: 'Before',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
      {
        value: 'after',
        label: 'After',
        params: {
          1: {
            type: 'options',
            dataType: 'list',
            isConditional: false,
            label: 'Condition',
            options: [
              'Today',
              'Tomorrow',
              'Yesterday',
              'Number Of Days From Now',
              'Number Of Days Ago',
              'Number Of Weeks From Now',
              'Number Of Weeks Ago',
              'Number Of Months From Now',
              'Number Of Months Ago',
              'Exact Date',
            ],
            value: null,
          },
          2: {
            type: 'input',
            dataType: null,
            isConditional: true,
            label: 'Date',
            value: null,
            dependency: {
              reference: 1,
              conditions: {
                'Exact Date': { dataType: 'date' },
                'Number Of Days From Now': { dataType: 'integer' },
                'Number Of Days Ago': { dataType: 'integer' },
                'Number Of Weeks From Now': { dataType: 'integer' },
                'Number Of Weeks Ago': { dataType: 'integer' },
                'Number Of Months From Now': { dataType: 'integer' },
                'Number Of Months Ago': { dataType: 'integer' },
              },
            },
          },
        },
      },
    ],
  },
  // Unsupported Field Types
  {
    value: 'ai',
    label: 'AI',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'password',
    label: 'Password',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'rollup',
    label: 'Roll Up',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'lookup',
    label: 'Look Up',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'autonumber',
    label: 'Autonumber',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'uuid',
    label: 'UUID',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'last_modified_by',
    label: 'Last Modified By',
    inputOptions: [],
    filterOptions: [],
  },
  {
    value: 'created_by',
    label: 'Created By',
    inputOptions: [],
    filterOptions: [],
  },
]
