import { FC } from 'react'

import {
  FindAutomationDashboardQuery,
  FindAutomationDashboardQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import AutomationMainSection from 'src/components/Automations/AutomationMainSection'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindAutomationDashboardQuery {
    automations: uncategorisedAutomations {
      ...AutomationFragment
    }
    automationCategories {
      id
      name
      rank
      parentCategoryId
      automations {
        ...AutomationFragment
      }
      childCategories {
        id
        name
        rank
        parentCategoryId
        automations {
          ...AutomationFragment
        }
      }
    }
  }

  fragment AutomationFragment on Automation {
    id
    name
    description
    onCreateTrigger
    onUpdateTrigger
    onDeleteTrigger
    triggerType
    matchCriteria
    automationTimeZone
    action
    active
    automationResourceId
    automationCategoryId
    automationRunsThisMonth
    lastRun
    isValidConfig {
      valid
      requirements
      checks
    }
  }
`
type SuccessProps = CellSuccessProps<
  FindAutomationDashboardQuery,
  FindAutomationDashboardQueryVariables
>

export type AutomationLog = {
  id: number
  clientId: number
  actionSuccess?: boolean
  triggerSuccess?: boolean
  createdAt: string
  completedAt?: string
}

export type AutomationLogGroup = {
  automation: CellAutomation
  automationRecords: AutomationLog[]
  hasErrors: boolean
}

export type CellAutomationCategory = SuccessProps['automationCategories'][0]
export type CellAutomations =
  SuccessProps['automationCategories'][0]['automations']
export type CellAutomation =
  SuccessProps['automationCategories'][0]['automations'][0]

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
  nextFetchPolicy: 'no-cache',
})

export const Empty = ({ queryResult: { refetch } }) => (
  <AutomationMainSection
    refetch={refetch}
    automationCategories={[]}
    uncategorisedAutomations={[]}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Loading = () => <LoadingSpinner />

export const Success: FC<SuccessProps> = ({
  automations,
  automationCategories,
  queryResult: { refetch },
}) => {
  return (
    <AutomationMainSection
      refetch={refetch}
      automationCategories={automationCategories}
      uncategorisedAutomations={automations}
    />
  )
}
