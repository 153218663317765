import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Autocomplete, TextField } from '@mui/material'
import {
  CreateAutomationCategory,
  CreateAutomationCategoryVariables,
  UpdateAutomationCategory,
  UpdateAutomationCategoryVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputRow } from 'src/components/Goals/Templates/TemplateEditForm'

import Modal from '../../Modal/Modal'
import { CellAutomationCategory } from '../AutomationCell'
import {
  CREATE_AUTOMATION_CATEGORY,
  UPDATE_AUTOMATION_CATEGORY,
} from '../queries'

export enum ModalState {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface AutomationCategoryOption {
  id: number
  label: string
  parentId?: number
}

interface AutomationCategoryModalProps {
  openModal: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  mode: ModalState
  automationCategoryOptions: AutomationCategoryOption[]
  editCategory: CellAutomationCategory
}

const AutomationCategoryModal: FC<AutomationCategoryModalProps> = ({
  openModal = false,
  handleClose,
  mode,
  automationCategoryOptions,
  editCategory,
}) => {
  const [categoryNameInput, setCategoryNameInput] = useState<string>('')

  const [categoryNameError, setCategoryNameError] = useState<string>('')

  const [categoryParentInput, setCategoryParentInput] =
    useState<AutomationCategoryOption>(null)

  const [automationCategoryLoading, setAutomationCategoryLoading] =
    useState(false)

  const [createAutomationCategory] = useMutation<
    CreateAutomationCategory,
    CreateAutomationCategoryVariables
  >(CREATE_AUTOMATION_CATEGORY, {
    onCompleted: () => {
      toast.success('Automation Category Created')
      handleModalClose()
    },
    onError: () => {
      toast.error('There was a problem creating your Category.')
      handleModalClose()
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const [updateAutomationCategory] = useMutation<
    UpdateAutomationCategory,
    UpdateAutomationCategoryVariables
  >(UPDATE_AUTOMATION_CATEGORY, {
    onCompleted: () => {
      toast.success('Automation Category Updated')
      handleModalClose()
    },
    onError: () => {
      toast.error('There was a problem updating your Category.')
      handleModalClose()
    },
    awaitRefetchQueries: true,
    refetchQueries: ['FindAutomationDashboardQuery'],
  })

  const handleAutomationCategoryCreate = async () => {
    const validNameInput = validateNameInput(categoryNameInput)

    if (validNameInput) {
      setAutomationCategoryLoading(true)

      await createAutomationCategory({
        variables: {
          input: {
            name: categoryNameInput,
            rank: 0,
            ...(categoryParentInput && {
              parentCategoryId: Number(categoryParentInput.id) ?? null,
            }),
          },
        },
      })
    }
  }
  const handleAutomationCategoryEdit = async () => {
    const validNameInput = validateNameInput(categoryNameInput)

    if (validNameInput) {
      setAutomationCategoryLoading(true)

      await updateAutomationCategory({
        variables: {
          id: editCategory.id,
          input: {
            name: categoryNameInput,
            parentCategoryId: Number(categoryParentInput?.id) ?? null,
          },
        },
      })
    }
  }

  const resetFormFields = () => {
    setCategoryNameInput('')
    setCategoryNameError('')
    setCategoryParentInput(null)
  }

  const handleModalSubmit = () => {
    mode === ModalState.CREATE && handleAutomationCategoryCreate()
    mode === ModalState.EDIT && handleAutomationCategoryEdit()
  }

  const handleModalClose = () => {
    resetFormFields()
    setAutomationCategoryLoading(false)
    handleClose(false)
  }

  const validateNameInput = (name: string) => {
    setCategoryNameError('')

    if (name?.length === 0) {
      setCategoryNameError('Category name cannot be empty.')
      return false
    }
    return true
  }

  // Set the new input values on editingCategory change
  useEffect(() => {
    const inputName = editCategory?.name || ''
    const inputParent = editCategory?.parentCategoryId
      ? automationCategoryOptions.find(
          (category) => category.id === editCategory?.parentCategoryId,
        )
      : null

    setCategoryNameInput(inputName)

    setCategoryParentInput(inputParent)
  }, [editCategory])

  // Don't let the error state show on modal open
  useEffect(() => {
    if (openModal) {
      setCategoryNameError('')
    }
  }, [openModal])

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      dialogClassName={'max-w-2xl'}
      childClassName="p-6"
      title={mode === ModalState.CREATE ? 'New Category' : 'Edit Category'}
      footerVisible
      confirmText={mode === ModalState.CREATE ? 'Create' : 'Save'}
      onConfirm={handleModalSubmit}
      loading={automationCategoryLoading}
      backDropClickDisable={automationCategoryLoading}
      cancelText="Cancel"
      onCancel={handleModalClose}
    >
      <FormInputRow label="Category Name">
        <TextField
          fullWidth
          placeholder="e.g. Marketing"
          data-intercom-target="automation-category-name-input"
          data-testid="automation-category-name-input"
          size="small"
          value={categoryNameInput}
          error={categoryNameError?.length > 0}
          helperText={categoryNameError}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const name = event.target.value
            validateNameInput(name)
            setCategoryNameInput(name)
          }}
        />
      </FormInputRow>
      <br />
      <FormInputRow label="Parent Category">
        <Autocomplete
          id="automation-category-select"
          size="small"
          options={automationCategoryOptions}
          disabled={
            automationCategoryOptions?.length === 0 ||
            editCategory?.childCategories?.length > 0
          }
          getOptionLabel={(option: AutomationCategoryOption) => option.label}
          renderOption={(props, option: AutomationCategoryOption) => {
            return <li {...props}>{option.label}</li>
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={
                automationCategoryOptions?.length === 0
                  ? 'No options available'
                  : 'Select a category'
              }
            />
          )}
          value={categoryParentInput}
          onChange={(_, newValue: AutomationCategoryOption) => {
            setCategoryParentInput(newValue)
          }}
          isOptionEqualToValue={(
            option: AutomationCategoryOption,
            value: AutomationCategoryOption,
          ) => {
            return option.id === value?.id
          }}
        />
      </FormInputRow>
    </Modal>
  )
}

export default AutomationCategoryModal
